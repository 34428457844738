

















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {userAPI} from "@/api/users";
import {envHelper} from "@/EnvHelper";
import {EventBus} from "@/EventBus";
import {mapActions, mapGetters} from "vuex";
import {utility} from "@/utility";

@Component({
    computed: {
        ...mapGetters('user', [
            'isAuth'
        ]),
        ...mapGetters('path', [
            'canGoForward', 'currentRequest', 'step'
        ])
    }
})
export default class NavBar extends Vue {
    @Prop() private readonly dibrief!: boolean
    @Prop() private readonly light!: boolean
    @Prop({default:false}) private readonly showLoginButton!: boolean

    private eb = EventBus
    private isAuth: any;
    private currentRequest: any;
    private path: any;

    get Dibrief() {
        return this.dibrief;
    }

    get Light() {
        return this.light;
    }

    logout() {
        this.$store.dispatch('user/logout').then(()=>{
          this.$router.push({name: 'Login'})
        })
    }

    back(){
      //@ts-ignore
      let url = utility.getUrlFromState(this.currentRequest.path, this.step -1)
      this.$router.push(url)
    }

    forward(){
      //@ts-ignore
      let url = utility.getUrlFromState(this.currentRequest.path, this.step +1)
      this.$router.push(url)
    }

    handleSave() {
        const state = this.$store.getters['path/getState']
        let componentName = ''
        componentName = state.component.name
        if (state) {
            EventBus.$emit(`Save${componentName}`)
        }
    }

    envHelper = envHelper
}
