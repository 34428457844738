






































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from "vuex";
import {utility} from "@/utility";
import {userAPI} from "@/api/users";
import {envHelper} from "@/EnvHelper";
import Page404 from "@/views/Page404.vue";

@Component({
  components: {
    Page404
  },
  computed: {
    ...mapGetters('user', [
      'user',
      'userEmail',
      'pendingRequests',
      'completedRequests',
      'isAdmin'
    ])
  },
})
export default class AdminDashboard extends Vue {
  private todo = true
  private done = true

  private utility = utility
  envHelper = envHelper
  private isAdmin: any;
  private mobileMenu = false

  created() {
    this.$store.dispatch('user/fetchRequests')
  }

  getActive(id: string) {
    return this.$route.name == 'Request' && this.$route.params.id === id
  }

  get show404() {
    return !this.isAdmin
  }

  logout() {
    userAPI.logout().then(() => {
      this.$store.dispatch('user/empty')
    }).then(() => {
      this.$router.push({name: 'Login'})
    })
  }

  @Watch('user')
  onUserChange() {
    this.$store.dispatch('user/fetchRequests')
  }

  toggleMobile() {
    this.mobileMenu = !this.mobileMenu
  }
}
