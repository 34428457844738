

































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mapActions, mapGetters} from "vuex";
import {requestApi} from "@/api/requests";
import {utility} from "@/utility";
import {EStatus} from "@/types";
import moment, {Moment} from "moment";

@Component({
  components: {},
  computed: {
    ...mapGetters('path', [
      'currentRequest',
      'getRequests'
    ]),
  },
  methods: {
    ...mapActions('path', [
      'fetchRequests'
    ])
  }
})
export default class Requests extends Vue {

  private utility = utility
  private e_status = EStatus
  private filter = ''
  private currentRequest: any;
  private loading = false;

  private fields = [
    {key: 'created', label: 'Data Creazione', sortable: true, formatter: this.customDataRender},
    {key: 'status', label: 'Stato', sortable: true},
    {key: 'company.businessName', label: 'Compagnia', sortable: true},
    {key: 'company.vatCode', label: 'PIVA', sortable: true},
    {key: 'assignedTo', label: 'Assegnatario', sortable: true},
    {key: 'button', label: ''}]

  private getRequests: any;

  customDataRender(val: Moment) {
    return val.format('DD/MM/Y HH:mm')
  }

  async handleClick(data: any) {
    if (data.item.status_enum == EStatus.Nuova) {
      await requestApi.requestUpdate(data.item.id, {status: EStatus.Letta})
    }
    this.$router.push({name: 'AdminRequestDetail', params: {id: data.item.id}})
  }

  sortCompare(aRow: any, bRow: any, key: any, sortDesc: any, formatter: any, compareOptions: any, compareLocale: any) {
    const a = aRow[key] // or use Lodash `_.get()`
    const b = bRow[key]
    if (
        (typeof a === 'object' && typeof b === 'object') ||
        (a instanceof moment && b instanceof moment)
    ) {
      // If both compared fields are native numbers or both are native dates
      return a < b ? -1 : a > b ? 1 : 0
    } else {
      // Otherwise stringify the field data and use String.prototype.localeCompare
      return this.toString(a).localeCompare(this.toString(b), compareLocale, compareOptions)
    }
  }

  toString(value: any): any {
    if (value === null || typeof value === 'undefined') {
      return ''
    } else if (value instanceof Object) {
      return Object.keys(value)
          .sort()
          .map(key => this.toString(value[key]))
          .join(' ')
    } else {
      return String(value)
    }
  }

  async created() {
    this.loading = true
    // @ts-ignore
    await this.fetchRequests()
    this.loading = false
  }

  rowClass(item: any, type: string) {
    if (item && type === 'row' && item.status in {"Cliente acquisito": 1, "Nuova": 1}) {
      return 'font-weight-bold'
    } else {
      return null
    }
  }

  get Requests() {
    return this.getRequests.filter((r: any) => {
      return r.status >= EStatus.Nuova
    }).map((r: any) => {
      return {
        id: r.id,
        created: moment(r.created),
        status: EStatus[r.status],
        status_enum: r.status,
        company: r.company,
        assignedTo: r.assigned_to
      }
    })
  }
}
