
































































import {Component, Prop, Vue} from 'vue-property-decorator';
import RadioSelect from "@/components/RadioSelect.vue";
import CaricamentoMassivo from "@/components/FormCard/CaricamentoMassivoCard.vue";
import CaricamentoSingoloCard from "@/components/FormCard/CaricamentoSingoloCard.vue";
import VeicoliCard from "@/components/FormCard/VeicoliCard.vue";
import {PoliciesBlockInterface, PoliciesResponse, PoliciesResponseInterface} from "@/api/PoliciesInterface";
import {mapActions, mapGetters} from "vuex";
import {EAssets, EPoliciesLoad, PolicyLoadSelect} from "@/types";
import {requestApi} from "@/api/requests";
import {EventBus} from "@/EventBus";
import {helper} from "@/helper";
import {utility} from "@/utility";

@Component({
  components: {
    RadioSelect,
    CaricamentoMassivo,
    CaricamentoSingoloCard,
    VeicoliCard,
  },
  computed: {
    ...mapGetters({
      'currentRequest': 'path/currentRequest',
      'getMassiveAssets': 'path/getMassiveAssets',
      'getPolicies': 'path/getPolicies',
      'step': 'path/step',
    })
  },
  methods: {
    ...mapActions('path', [
      'refreshAssets',
      'refreshPolicies',
      'refreshPolicyAnswers'
    ])
  }
})


export default class CaricamentoPolizze extends Vue {
  @Prop() private msg!: string;

  private caricamento_radio = 0
  private currentRequest: any;
  private getPolicies: any;
  private confirmModalVisibility: boolean = false;
  private disabled: boolean = false;
  private show_error: boolean = false;

  private proceedP: Promise<any> = new Promise(() => {
  })
  private successUploadType: number = 0
  private step!: number;


  created() {
    this.$store.dispatch('path/refreshPolicies')
    this.$store.dispatch('path/refreshPolicyAnswers')
    EventBus.$on(`Save${this.$options.name}`, () => {
      this.save(true, false)
    })
  }

  beforeDestroy() {
    EventBus.$off(`Save${this.$options.name}`)
  }

  get areCardInvalid() {
    return this.getPolicies.some((p: any) => {
      return !p.valid
    })
  }

  save(skip_alert?: boolean, forward: boolean = true) {
    this.disabled = true
    if (this.areCardInvalid) {
      // non valida
      if (!skip_alert) {
        this.show_error = true
        EventBus.$emit('showHelp', helper.polizze_uncomplete)
      }
      this.disabled = false
    } else {
      if (forward) {
        EventBus.$emit('ForwardIfComplete')
      } else {
        this.disabled = false
      }

    }
  }


  get UploadType() {
    return this.currentRequest.uploadType
  }

  handleOk() {
    if (this.currentRequest.uploadType == EPoliciesLoad.MASSIVO || this.currentRequest.uploadType == EPoliciesLoad.SINGOLO) {
      this.destroyAssets()
    }
  }

  handleCancel() {
    this.confirmModalVisibility = false
  }

  set UploadType(value: number) {
    if (this.currentRequest.uploadType == EPoliciesLoad.MASSIVO || this.currentRequest.uploadType == EPoliciesLoad.SINGOLO) {
      this.confirmModalVisibility = true
      this.successUploadType = value
    } else {
      this.$store.dispatch('path/updateRequest',
          {uploadType: value}
      )
    }
  }

  destroyAssets() {
    return requestApi.requestAssetsTypeDestroy(this.currentRequest.id, EAssets.POLIZZA).finally(() => {
      this.$store.dispatch('path/updateRequest', {uploadType: this.successUploadType})
    })
  }

  private value: any[] = []

  EPoliciesLoad = EPoliciesLoad

  caricamentoSingoloCardTitle(title: string) {
    return "Dibrief-analisi-polizze-step-" + (this.step + 1).toString() + "-" + utility.stringToSlug(title)
  }

}
