






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ErrorTp extends Vue {
  @Prop() private readonly right!: string
}
