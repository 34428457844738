import DatiAzienda from "@/views/DatiAzienda.vue";
import RiskAssessment from "@/views/RiskAssessment.vue";
import EntePubblico from "@/views/EntePubblico.vue";
import EndRiskAssessment from "@/views/EndRiskAssessment.vue";
import CaricamentoPolizze from "@/views/CaricamentoPolizze.vue";
import End from "@/views/End.vue";
import EndPolizze from "@/views/EndPolizze.vue";
import {ETarget} from "@/types";

interface StringKeyObj {
    [key: string]: any;
}

export const Percorsi: StringKeyObj = {
    0: [
        {
            title: 'partiamo dai fondamentali',
            component: DatiAzienda
        },
        {
            title: 'analisi delle polizze',
            component: CaricamentoPolizze
        },
        {
            title: 'complimenti, l\'analisi delle polizze è completata',
            component: EndPolizze
        },
        {
            title: 'analisi del rischio aziendale',
            component: RiskAssessment
        },
        {
            title: 'complimenti, hai appena completato il tuo percorso',
            component: End
        }
    ],
    1: [
        {
            title: 'partiamo dai fondamentali',
            component: DatiAzienda
        },
        {
            title: 'analisi del rischio aziendale',
            component: RiskAssessment
        },
        {
            title: 'analisi del rischio aziendale',
            component: EndRiskAssessment
        },
        {
            title: 'analisi delle polizze',
            component: CaricamentoPolizze
        },
        {
            title: 'complimenti, hai appena completato il tuo percorso',
            component: End
        }
    ],
    2: [
        {
            title: 'partiamo dai fondamentali',
            component: DatiAzienda
        },
        {
            title: 'analisi del rischio aziendale',
            component: RiskAssessment
        },
        {
            title: 'analisi delle polizze',
            component: CaricamentoPolizze
        },
        {
            title: 'complimenti, hai appena completato il tuo percorso',
            component: End
        }
    ],
    3: [
        {
            title: 'partiamo dai fondamentali',
            component: DatiAzienda
        },
        {
            title: 'questionario ente pubblico',
            component: EntePubblico
        },
        {
            title: 'complimenti, hai appena completato il tuo percorso',
            component: End
        }
    ]
}

class LocalStorage {
    stepper: number = 0;
    step: number = 0;

    constructor() {
        this.stepper = Number.parseInt(localStorage.getItem('DIB_STEPPER') || 0 + '')
        this.step = Number.parseInt(localStorage.getItem('DIB_STEP') || '0')

        this.stepper = Number.isNaN(this.stepper) ? 0 : this.stepper
        this.step = Number.isNaN(this.step) ? 0 : this.step
    }

    setStepper(key: number, idx: number){
        if(Percorsi.hasOwnProperty(key)){
            this.stepper = key
            this.step = idx
        }
        return this
    }

    save() {
        localStorage.setItem('DIB_STEPPER', this.stepper + '')
        localStorage.setItem('DIB_STEP', this.step + '')
    }

    isPending(){
        return Percorsi[this.stepper] || false
    }

    loadStepper(){
        if(Percorsi.hasOwnProperty(this.stepper)){
            return Percorsi[this.stepper]
        }
        return []
    }

    loadStep(){
        if(Percorsi.hasOwnProperty(this.stepper)){
            return this.step
        }
        return 0
    }

    loadPath(){
        if(Percorsi.hasOwnProperty(this.stepper)){
            return this.stepper
        }
        throw 'not valid'
    }

    getStepper(key: number){
        if(Percorsi.hasOwnProperty(key)){
            return Percorsi[key]
        }
        throw 'not valid'
    }
}

export const dibLs = new LocalStorage()