















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class Radio extends Vue {
  @Prop() private readonly label!:string
  @Prop() private readonly selected!:boolean
  @Prop() private readonly value!:number
}
