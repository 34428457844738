












import {Component, Prop, Vue} from 'vue-property-decorator';
import {QuestionInterface} from "@/api/QuestionInterface";

@Component({
    components: {}
})
export default class CheckBoxQuestion extends Vue {
    @Prop() private readonly description!: string
    @Prop() private readonly question!: string
    @Prop() private readonly value!: boolean

    get Value() {
        return this.value
    }

    set Value(val: boolean) {
        this.$emit('input', {question: this.question, value: val})
    }
}
