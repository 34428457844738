


























import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import InfoPoint from "@/components/InfoPoint.vue";
import {EventBus} from "@/EventBus";
import {mapGetters} from "vuex";
import {helper} from "@/helper";

@Component({
    components: {
        Card,
        CardInput,
        InfoPoint
    },
    computed: {
        ...mapGetters('path', [
            'currentRequest',
        ])
    },
})
export default class OrganicoCard extends Vue {
    @Prop() private readonly showError!: boolean;

    private employees: number | null = null;
    private nd_show_error: boolean = true;
    private currentRequest: any;

    private helper = helper

    showHelp($event: any) {
        EventBus.$emit('showHelp', $event)
    }

    created() {
        if (this.currentRequest) {
            this.employees = this.currentRequest.company.employees
            this.validate()
        }
    }

    validate() {
        this.nd_show_error = !this.employees;

        //se e valida ritorna true
        this.$emit('valid',
            !this.nd_show_error
        )

        this.$emit('input', {
            employees: this.employees,
        })
    }
}
