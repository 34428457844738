





import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class InfoPoint extends Vue {
  @Prop() private readonly icon!: string;

  @Prop() private readonly title!: string;
  @Prop() private readonly text!: string;
}
