
































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mapActions, mapGetters} from "vuex";
import {utility} from "@/utility";
import {EStatus} from "@/types";
import {requestApi} from "@/api/requests";

@Component({
  components: {},
  computed: {
    ...mapGetters('path', [
      'getUsers'
    ]),
  },
  methods: {
    ...mapActions('path', [
      'fetchUsers'
    ])
  }
})
export default class Users extends Vue {

  private utility = utility
  private e_status = EStatus
  private filter = ''
  private loading = false;

  private fields = [
    {key: 'email', label: 'Email', sortable: true},
    {key: 'firstName', label: 'Nome', sortable: true},
    {key: 'lastName', label: 'Cognome', sortable: true},
    // {key: 'role', label: 'Ruolo', sortable: true},
    {key: 'admin', label: 'Admin'}]

  private getUsers: any;

  async changeAdminSwitch(data: any) {
    let userId: string = data.item.id
    if (this.isAdmin(data.item)) {
      await requestApi.userUpdate(userId, {
        is_staff: false
      })
    } else {
      await requestApi.userUpdate(userId, {
        is_staff: true
      })
    }


  }

  async created() {
    this.loading = true
    // @ts-ignore
    await this.fetchUsers()
    this.loading = false
  }

  rowClass(item: any, type: string) {
    if (item && type === 'row' && item.status === 'Nuova') {
      return 'font-weight-bold'
    } else {
      return null
    }
  }

  get Users() {
    return this.getUsers
  }

  isAdmin(user: any) {
    return user.is_staff
  }
}
