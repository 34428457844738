


















import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CheckBoxQuestion from "@/components/FormCard/CheckBoxQuestion.vue";
import {QuestionInterface} from "@/api/QuestionInterface";


@Component({
    components: {
        Card,
        CheckBoxQuestion
    }
})
export default class CheckBoxCard extends Vue {
    @Prop() private readonly title!: string;
    @Prop() private readonly questions!: any[];
}
