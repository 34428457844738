









import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    components: {
        Loading,
    }
})
export default class Loading extends Vue {
    @Prop({default: false}) private readonly isLoading!: boolean;
}
