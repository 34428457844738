function enumToDict<T>(en: T) {
    return Object.keys(en).filter(f => !isNaN(Number(f))).map(f => {
        // @ts-ignore
        return {value: Number(f), text: en[f]}
    });
}

export enum ETarget {
    'ente pubblico',
    'società',
    'startup',
    'libero professionista',
}

export enum EStatus {
    'Da completare',
    'Nuova',
    'Letta',
    'Presa in carico',
    'Proposta inviata',
    'Cliente acquisito'
}

export enum EFunzioni {
    'Rimetti come nuova',
    'Prendi in carico' = 2,
    'Invia proposta' = 3,
    'Acquisisci cliente' = 4,
}

// Nuova, Letta, Presa in carico, Proposta Inviata, Cliente acquisito

export enum ELegalForm {
    'Società Semplice',
    'S.N.C. - Società in Nome Collettivo',
    'S.A.S.- Società in Accomandita Semplice',
    'S.R.L. - Società a Responsabilità Limitata',
    'S.P.A. - Società Per Azioni',
    'S.A.P.A. - Società in Accomandita Per Azioni',
    'Società cooperativa',
}


export enum ESector {
    'Industria',
    'Commercio',
    'Artigianato',
    'Agricoltura',
    'Libera Professione',
    'Altro',
}


export enum EPuclicLegalForm {
    'Comune o Unione di Comuni',
    'Provincia',
    'Società partecipata',
    'Ente pubblico non economico',
    'Consorzio',
    'Comunità Montana',
    'Altro',
}


export enum EMarket {
    'Italia',
    'Europa',
    'Paesi Extraeuropei eccetto USA e Canada',
    'Paesi Extraeuropei inclusi USA e Canada',
}

export enum EPoliciesLoad {
    NESSUNO,
    MASSIVO,
    SINGOLO,
}

export enum EAssets {
    VEICOLO,
    PROPOSTA,
    POLIZZA,
    MANDATO,
    BILANCIO_CONSUNTIVO_ENTE_PUBBLICO,
    BILANCIO_PREVISIONALE_ENTE_PUBBLICO
}

export enum EYesNo {
    TRUE = 'Sì',
    FALSE = 'No'
}

export interface DetailAssetsInterface {
    id: string,
    type: EAssets,
    file: string
    name: string
}

export interface DetailResponseInterface {
    question: string
    response: string
    assets: DetailAssetsInterface[]
}

export enum EPolicyStatus {
    'Coperto',
    'Non coperto',
    'In scadenza',
    'Non ho mai sottoscritto questa polizza',
}

export enum EPolicyNeed {
    'Desidero ricevere una proposta',
    'Non desidero ricevere una proposta',
    'Non conosco la polizza, vorrei maggiori info ',
}

export enum EVehicleUse {
    'personale',
    'aziendale'
}

export enum ECvt {
    'I',
    'F',
    'K'
}

export enum ECompany {
    'COMPAGNIA0',
    'COMPAGNIA1',
    'COMPAGNIA2',
}

export enum ERealEstateType {
    'Proprietà',
    'Locazione',
    'Altro'
}

export enum EPercorsi{
  'Analisi delle polizze',
  'Analisi del rischio aziendale',
  'Analisi completa',
  'Ente pubblico'
}

export const SectorSelect = enumToDict(ESector);
export const PuclicLegalFormSelect = enumToDict(EPuclicLegalForm)
export const LegalFormSelect = enumToDict(ELegalForm);
export const MarketSelect = enumToDict(EMarket)
export const PolicyStatusSelect = enumToDict(EPolicyStatus)
export const PolicyNeedSelect = enumToDict(EPolicyNeed)
export const CarUseSelect = enumToDict(EVehicleUse)
export const CVTSelect = enumToDict(ECvt)
export const CompanySelect = enumToDict(ECompany)
export const StatusSelect = enumToDict(EStatus)
export const RealEstateTypeSelect = enumToDict(ERealEstateType)
export const PercorsiSelect = enumToDict(EPercorsi)
export const PolicyLoadSelect = [
    {
        value: EPoliciesLoad.MASSIVO,
        label: 'Caricamento massivo',
        text: ''
    },
    {
        value: EPoliciesLoad.SINGOLO,
        label: 'Caricamento singolo',
        text: ''
    },
    {
        value: EPoliciesLoad.NESSUNO,
        label: 'Nessun caricamento',
        text: ''
    },
]