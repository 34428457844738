


















import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import InfoPoint from "@/components/InfoPoint.vue";
import Dnd from "@/components/Dnd.vue";
import {requestApi} from "@/api/requests";
import {mapActions, mapGetters} from "vuex";
import {RealEstateTypeSelect} from "@/types";
import {EventBus} from "@/EventBus";
import BDate from "@/views/b-date.vue";
import {PublicAuthorityStaffInterface} from "@/api/RequestsInterface";
import {utility} from "@/utility";

@Component({
  components: {
    BDate,
    Card,
    CardInput,
    InfoPoint,
  },
  computed: {
    ...mapGetters('path', [
      'currentRequest', 'publicAuthority'
    ])
  },
  methods: {
    ...mapActions('path', [
      'refreshRequest',
    ])
  }
})
export default class StaffTableCard extends Vue {

  @Prop({default: false}) private readonly readonly!: boolean

  private currentRequest: any
  private publicAuthority: any
  private modified: boolean = false

  private staff = [
    {category: 'amministratori', number: 0, label: 'Amministratori'},
    {category: 'dirigenti_amministrativi', number: 0, label: 'Dirigenti Amministrativi'},
    {category: 'funzionari_amministrativi', number: 0, label: 'Funzionari Amministrativi'},
    {category: 'dirigenti_tecnici', number: 0, label: 'Dirigenti Tecnici'},
    {category: 'tecnici', number: 0, label: 'Tecnici'},
    {category: 'altro', number: 0, label: 'Altro'},
  ]

  private staffFields = [
    {key: 'label', label: 'Categoria'},
    {key: 'number', label: 'Numero'},
  ]

  created() {
    if(this.publicAuthority){
      this.staff = utility.mergeBykey(this.staff, this.publicAuthority.staff || [], 'category')
    }
  }

  public getData() {
    return this.staff
  }

}
