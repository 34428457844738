export const helper = {
    validita_polizza: {
        title: '',
        text: ''
    },
    desiderio_polizza: {
        title: '',
        text: ''
    },
    upload_polizza: {
        title: '',
        text: ''
    },
    bilancio: {
        title: 'Perché abbiamo bisogno di questi dati?',
        text: ''
    },
    budgetCurrent: {
        title: 'Supporto tecnico',
        text: ''
    },
    budgetEstimated: {
        title: 'Supporto tecnico',
        text: ''
    },
    success_login: {
        title: 'Complimenti',
        text: 'Ti abbiamo inviato una mail '
    },
    polizze_uncomplete: {
        title: 'Aspetta!',
        text: 'Ci sono delle polizze incomplete o senza risposte. Prima di andare avanti assicurati di completarle tutte.'
    },
    organico: {
        title: '',
        text: 'Inserire il n° totale dei dipendenti/collaboratori dell\'azienda'
    }
}
