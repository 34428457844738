























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import InfoPoint from "@/components/InfoPoint.vue";
import Dnd from "@/components/Dnd.vue";
import {requestApi} from "@/api/requests";
import {mapActions, mapGetters} from "vuex";
import {RealEstateTypeSelect} from "@/types";
import {EventBus} from "@/EventBus";
import BDate from "@/views/b-date.vue";
import {utility} from "@/utility";

@Component({
    components: {
        BDate,
        Card,
        CardInput,
        InfoPoint,
    },
    computed: {
        ...mapGetters('path', [
            'publicAuthority'
        ])
    },
    methods: {
        ...mapActions('path', [
            'refreshRequest',
        ])
    }
})
export default class RealEstateTableCard extends Vue {

    @Prop({default:false}) private readonly readonly!: boolean

    private publicAuthority: any;
    private RealEstateTypeSelect = RealEstateTypeSelect
    private static_rows =  ['mobilio', 'macchine-ufficio-ed-elaboratori-elettronici']

    private real_estate_description: string = ''
    private real_estate_forniture_total_value: number = 0

    private rows: any[] = []
    private fornitureRows: any[] = [
      {type: 'mobilio', description: 'Mobilio', value: 0},
      {type: 'macchine-ufficio-ed-elaboratori-elettronici', description: 'Macchine ufficio ed elaboratori elettronici', value: 0}
    ]

    private fields = [
        {key: 'location', label: 'Ubicazione'},
        {key: 'intended_use', label: 'Destinazione (prevalente)'},
        {key: 'mq', label: 'Superficie MQ.'},
        {key: 'type', label: 'Tipologia'},
        {key: 'value', label: 'Valore (*)'},
        {key: 'delete', label: ''}
    ]

    private fornitureFields = [
        {key: 'description', label: 'Descrizione'},
        {key: 'value', label: 'Valore'},
        {key: 'delete', label: ''}
    ]

    addRow() {
        if(this.readonly) return;
        this.rows.push({location: '', intended_use: '', mq: 0, type: null, value: 0},)
    }

    addFornitureRow() {
        if(this.readonly) return;
        this.fornitureRows.push({type: '', description: '', value: 0},)
    }

    async removeRow(row: any) {
        this.rows.splice(row.index, 1)
    }

    async removeFornitureRow(row: any) {
        this.fornitureRows.splice(row.index, 1)
    }

    created() {
    if(this.publicAuthority){
      this.rows = this.publicAuthority?.real_estate_list || []
      this.fornitureRows = utility.mergeBykeyNG(this.fornitureRows, this.publicAuthority?.real_estate_furniture, 'type')
      this.real_estate_description = this.publicAuthority.real_estate_description || ''
      this.real_estate_forniture_total_value = this.publicAuthority.real_estate_forniture_total_value || 0

    }
  }

    public getData() {

      return {
        real_estate_list: this.rows,
        real_estate_furniture: this.fornitureRows.map((item)=>{
          item.type = utility.stringToSlug(item.description)
          return item
        }),
        real_estate_description: this.real_estate_description,
        real_estate_forniture_total_value: this.real_estate_forniture_total_value
      }
    }

}
