




















import {Component, Prop, Vue} from 'vue-property-decorator';
import Dnd from "@/components/Dnd.vue";
import CaricamentoSingoloCard from "@/components/FormCard/CaricamentoSingoloCard.vue";
import {mapActions, mapGetters} from "vuex";
import {requestApi} from "@/api/requests";
import {EAssets} from "@/types";

@Component({
    components: {
        CaricamentoSingoloCard,
        Dnd
    },
    computed: {
        ...mapGetters('path', [
            'currentRequest',
            'getMassiveAssets'
        ])
    },
    methods: {
        ...mapActions('path', [
            'refreshRequest',
        ])
    }
})
export default class CaricamentoMassivoCard extends Vue {
    private currentRequest: any;
    private isLoading: boolean = false;
    private complete: boolean = false

    send(files: File[]) {
      this.isLoading = true

      let uploads = files.map((file) => {
        return requestApi.requestAssetsCreate(this.currentRequest.id, file, EAssets.POLIZZA).then(() => {
        })
      })

      Promise.all(uploads).then(() => {
        // @ts-ignore
        this.refreshRequest()
      }).finally(()=>{
        this.complete = !this.complete
        this.isLoading = false
      })

    }

    handleFileRemove(id: string){
        this.isLoading = true
        requestApi.requestAssetsDestroy(this.currentRequest.id, id).then(() => {
            // @ts-ignore
            this.refreshRequest()
        }).finally(()=>{
            this.isLoading = false
        })
    }
}

