

























import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import InfoPoint from "@/components/InfoPoint.vue";
import Dnd from "@/components/Dnd.vue";
import {requestApi} from "@/api/requests";
import {mapActions, mapGetters} from "vuex";
import {RealEstateTypeSelect} from "@/types";
import {EventBus} from "@/EventBus";
import BDate from "@/views/b-date.vue";
import {utility} from "@/utility";

@Component({
    components: {
        BDate,
        Card,
        CardInput,
        InfoPoint,
    },
    computed: {
        ...mapGetters('path', [
            'currentRequest', 'publicAuthority'
        ])
    },
    methods: {
        ...mapActions('path', [
            'refreshRequest',
        ])
    }
})
export default class WagesTableCard extends Vue {

    @Prop({default:false}) private readonly readonly!: boolean

    private currentRequest: any
    private publicAuthority: any
    private modified: boolean = false

    private wages = [
    {
      category: "dipenddenti_ed_assmilati",
      last_year: 0,
      current_year: 0,
      notes: "",
      label: "Personale dipendente ed assimilato"
    },
    {
      category: "altro",
      last_year: 0,
      current_year: 0,
      notes: "",
      label: "Altro"
    }
  ]

  private wagesFields = [
    {key: 'label', label: 'Categoria'},
    {key: 'last_year', label: 'Ultimo Anno'},
    {key: 'current_year', label: 'Anno in corso (previsione)'},
    {key: 'note', label: 'Note'},
  ]

  created() {
    if(this.publicAuthority){
      this.wages = utility.mergeBykey(this.wages,this.publicAuthority?.wages || [],  'category')
    }
  }

  public getData(){
      return this.wages
  }


}
