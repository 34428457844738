









































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CardInput from "@/components/CardInput.vue";
import BilancioCard from "@/components/FormCard/BilancioCard.vue";
import CheckBoxCard from "@/components/FormCard/CheckBoxCard.vue";
import VeicoliCard from "@/components/FormCard/VeicoliCard.vue";
import {mapActions, mapGetters} from "vuex";
import {requestApi} from "@/api/requests";
import {QuestionBlockInterface, QuestionResponse} from "@/api/QuestionInterface";
import {RequestsQuestionCreateInterface} from "@/api/RequestsInterface";
import {EventBus} from "@/EventBus";
import {utility} from "@/utility";

@Component({
  components: {
    BilancioCard,
    CardInput,
    CheckBoxCard,
    VeicoliCard
  },
  computed: {
    ...mapGetters({
      'getQuestions': 'path/getQuestions',
      'currentRequest': 'path/currentRequest',
      'getAnswers': 'path/getAnswers',
      'step': 'path/step'
    })
  },
  methods: {
    ...mapActions('path', [
      'refreshAnswers',
      'refreshQuestions',
      'updateQuestionResponseInsecure'
    ])
  }
})
export default class RiskAssessment extends Vue {
  @Prop() private msg!: string;
  private currentRequest: any;
  private getQuestions: any;
  private getAnswers: any;
  private disabled: boolean = false;
  private step: any;

  save(forward: boolean = true) {
    this.disabled = true
    // @ts-ignore
    this.$refs['veicoli'].saveCars().then(() => {
      if (forward) {
        EventBus.$emit('ForwardIfComplete')
      }
    }).finally(() => {
      this.disabled = false
    });
  }

  created() {
    //@ts-ignore
    this.refreshQuestions();
    //@ts-ignore
    this.refreshAnswers();
    EventBus.$on(`Save${this.$options.name}`, () => {
      this.save(false)
    })
  }

  beforeDestroy() {
    EventBus.$off(`Save${this.$options.name}`)
  }


  handleInput(event: RequestsQuestionCreateInterface) {
    requestApi.requestQuestionsSetAnswer(this.currentRequest.id, event.question, event).then(() => {
      //@ts-ignore
      this.refreshAnswers();
    })
  }

  parcoVeicolicardTitle() {
    return "Dibrief-rischio-aziendale-step-" + (this.step + 1).toString() + "-parco-veicoli"
  }

  checkboxCardTitle(title: string) {
    return "Dibrief-rischio-aziendale-step-" + (this.step + 1).toString() + "-" + utility.stringToSlug(title)
  }
}
