


























































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mapActions, mapGetters} from "vuex";
import {requestApi} from "@/api/requests";
import {QuestionResponse} from "@/api/QuestionInterface";
import CheckBoxCard from "@/components/FormCard/CheckBoxCard.vue";
import RecapCard from "@/components/FormCard/RecapCard.vue";
import {EAssets, EFunzioni, StatusSelect} from "@/types";
import {envHelper} from "@/EnvHelper";
import {
  ELegalForm,
  EMarket,
  EPoliciesLoad,
  EPolicyNeed,
  EPolicyStatus,
  EPuclicLegalForm,
  ESector,
  EStatus,
  ETarget,
  EYesNo,
} from "@/types";
import {PoliciesResponse} from "@/api/PoliciesInterface";
import VeicoliCard from "@/components/FormCard/VeicoliCard.vue";
import Dnd from "@/components/Dnd.vue";
import Card from "@/components/Card.vue";
import Loading from "@/components/Loading.vue";
import RealEstateTableCard from "@/components/FormCard/RealEstateTableCard.vue";
import InsuraceContractsTableCard from "@/components/FormCard/InsuraceContractsTableCard.vue";
import ClaimsCoveredTableCard from "@/components/FormCard/ClaimsCoveredTableCard.vue";
import ClaimsNotCoveredTableCard from "@/components/FormCard/ClaimsNotCoveredTableCard.vue";
import StaffTableCard from "@/components/FormCard/StaffTableCard.vue";
import WagesTableCard from "@/components/FormCard/WagesTableCard.vue";
import {utility} from "@/utility";
import {PublicAuthorityInterface} from "@/api/RequestsInterface";

@Component({
  components: {
    VeicoliCard,
    RecapCard,
    CheckBoxCard,
    Dnd,
    Card,
    Loading,
    RealEstateTableCard,
    InsuraceContractsTableCard,
    ClaimsCoveredTableCard,
    ClaimsNotCoveredTableCard,
    StaffTableCard,
    WagesTableCard,
  },
  computed: {
    ...mapGetters('user', [
      'user',
      'userEmail',
      'userFirstName',
      'userLastName',
      'userRole',
      'isAdmin'
    ]),
    ...mapGetters('path', [
      'currentRequest',
      'publicAuthority',
      'getMassiveAssets',
      'getMandatiAssets',
      'getBilancioConsuntivoEntePubblicoAssets',
      'getBilancioPrevisionaleEntePubblicoAssets',
      'getPolicies',
      'getQuestions'
    ])
  },
  methods: {
    ...mapActions('path', [
      'refreshAssets',
    ]),
    ...mapActions('policies', [
      'fetchPolicies',
    ])
  }
})
export default class RequestDetail extends Vue {
  @Prop() private readonly id!: string;

  private user?: any;
  private currentRequest: any;
  private publicAuthority: PublicAuthorityInterface | undefined;
  private getMassiveAssets: any;
  private getMandatiAssets: any;
  private getBilancioPrevisionaleEntePubblicoAssets: any;
  private getBilancioConsuntivoEntePubblicoAssets: any;

  private complete = false;
  private isLoading = false;
  private loading = false;
  private processingPrint = false;
  private exportDownloadUrl = ''

  StatusSelect = StatusSelect;
  envHelper = envHelper;
  EStatus = EStatus;
  efunzioni = EFunzioni;

  get ButtonLabel() {
    return EFunzioni[this.currentRequest?.status]
  }

  get ShowSetLetta() {
    return false
    // return this.currentRequest?.status == EStatus.Letta
  }

  get ShowChangeStatusButton() {
    return false
    //return this.currentRequest?.status >= EStatus.Letta && this.currentRequest?.status <= EStatus["Proposta inviata"]
  }

  get AllowedStatusSelect() {
    let statusArray = [
      StatusSelect[EStatus.Nuova],
      StatusSelect[EStatus.Letta],
      StatusSelect[EStatus["Presa in carico"]],
      StatusSelect[EStatus["Proposta inviata"]],
      StatusSelect[EStatus["Cliente acquisito"]],
    ]
    if (this.currentRequest?.status == EStatus.Letta) {
      return statusArray.slice(this.currentRequest?.status - 2, this.currentRequest?.status + 1)
    } else {
      return statusArray.slice(this.currentRequest?.status - 1, this.currentRequest?.status + 1)
    }
  }

  send(files: File[]) {
    this.isLoading = true

    let uploads = files.map((file) => {
      return requestApi.requestAssetsCreate(this.currentRequest.id, file, EAssets.PROPOSTA).then(() => {
      })
    })

    Promise.all(uploads).then(() => {
      // @ts-ignore
      this.refreshAssets()
    }).finally(() => {
      this.complete = !this.complete
      this.isLoading = false
    })
  }

  get ReadonlyDnd() {
    return this.currentRequest?.status !== EStatus["Presa in carico"]
  }

  handleFileRemove(id: string) {
    this.isLoading = true
    requestApi.requestAssetsDestroy(this.currentRequest.id, id).then(() => {
      // @ts-ignore
      this.refreshAssets()
    }).finally(() => {
      this.isLoading = false
    })
  }

  created() {
    this.refresh()
  }

  get Status() {
    return this.currentRequest?.status
  }

  get AssisgnedTo() {
    return this.currentRequest?.assigned_to
  }

  set Status(value: number) {
    this.handleStatusChange(value)
  }

  rimettiNuova() {
    this.handleStatusChange(EStatus.Nuova)
  }

  printRequest() {
    if (!this.processingPrint) {
      this.processingPrint = true
      window.print()
      this.processingPrint = false
    }
  }

  get cantChangeStatus() {
    return this.currentRequest?.status == EStatus["Presa in carico"] && !this.getMandatiAssets.length
  }

  handleStatusChange(status?: number) {
    if (this.cantChangeStatus) {
      return
    }
    let val = status
    if (val == undefined) {
      val = this.currentRequest.status + 1
    }
    this.$store.dispatch('path/updateRequest', {status: val}).then(() => {
      if (val == EStatus.Nuova) {
        this.$router.replace({name: 'Requests'})
      }
    })
  }

  get isSingolo() {
    return this.currentRequest?.uploadType === EPoliciesLoad.SINGOLO
  }

  get isMassivo() {
    return this.currentRequest?.uploadType === EPoliciesLoad.MASSIVO
  }

  get isEntePubblico() {
    return this.currentRequest?.company.target == ETarget["ente pubblico"]
  }

  @Watch('id')
  refresh() {
    if (this.user) {
      this.loading = true
      this.$store.dispatch('path/loadRequest', this.id).finally(() => {
        this.loading = false
      })
    }
    //@ts-ignore
    document.querySelector('html').scrollTop = 0
  }

  get Completed() {
    return this.currentRequest && this.currentRequest.status != EStatus["Da completare"]
  }

  handleRiprendi() {
    if (this.currentRequest) {
      let url = utility.getUrlFromState(this.currentRequest.path, this.currentRequest.step)
      this.$router.push(url)
    }
  }

  getQuestionRow(questions: any[]) {
    return questions.map((q) => {
      return {
        question: q.description,
        response: q.value ? EYesNo.TRUE : EYesNo.FALSE,
        assets: []
      }
    })
  }

  getMassiveRow() {
    return this.getMassiveAssets.map((a: any) => {
      return {
        question: a.name,
        assets: [{
          name: a.name,
          path: a.file
        }]
      }
    })
  }

  get UserRow() {
    return [
      {
        question: 'Email',
        response: this.currentRequest?.user?.email,
        assets: []
      },
      {
        question: 'Nome',
        response: this.currentRequest?.user?.firstName,
        assets: []
      },
      {
        question: 'Cognome',
        response: this.currentRequest?.user?.lastName,
        assets: []
      },
      {
        question: 'Ruolo',
        response: this.currentRequest?.user?.role,
        assets: []
      },
    ];
  }

  get CompanyRow() {
    if (this.currentRequest?.company) {
      if (this.currentRequest.company.target == ETarget["ente pubblico"]) {
        return [
          {
            question: 'Target',
            response: ETarget[this.currentRequest.company.target],
            assets: []
          },
          {
            question: 'Nome',
            response: this.currentRequest.company.businessName,
            assets: []
          },
          {
            question: 'Bilancio Consolidato',
            response: '',
            assets: this.getBilancioConsuntivoEntePubblicoAssets
          },
          {
            question: 'Bilancio Previsionale',
            response: '',
            assets: this.getBilancioPrevisionaleEntePubblicoAssets
          },
          {
            question: 'Numero di dipendenti',
            response: this.currentRequest.company.employees,
            assets: []
          },
        ]
      } else {
        return [
          {
            question: 'Target',
            response: ETarget[this.currentRequest.company.target],
            assets: []
          },
          {
            question: 'Nome',
            response: this.currentRequest.company.businessName,
            assets: []
          },
          {
            question: 'Partita IVA',
            response: this.currentRequest.company.vatCode,
            assets: []
          },
          {
            question: 'Tipologia',
            response: ELegalForm[this.currentRequest.company.legalForm],
            assets: []
          },
          {
            question: 'Settore',
            response: ESector[this.currentRequest.company.sector],
            assets: []
          },
          {
            question: 'Codice Ateco',
            response: this.currentRequest.company.ateco,
            assets: []
          },
          {
            question: 'Mercati in cui opera',
            response: (this.currentRequest.company?.market || []).map((m: number) => {
              return EMarket[m]
            }).join(', '),
            assets: []
          },
          {
            question: 'Fatturato anno precedente',
            response: this.currentRequest.company.budgetCurrent,
            assets: []
          },
          {
            question: 'Fatturato previsione anno in corso',
            response: this.currentRequest.company.budgetEstimated,
            assets: []
          },
          {
            question: 'Numero di dipendenti',
            response: this.currentRequest.company.employees,
            assets: []
          },
        ]
      }
    }
    return []

  }

  get AddressRow() {
    return [{
      question: this.publicAuthority?.address || ''
    }]
  }

  get StructuralChangesRow() {
    let res = []
    res.push(
        {
          question: 'Sono in previsione cambiamenti strutturali?',
          response: this.publicAuthority?.structural_changes ? EYesNo.TRUE : EYesNo.FALSE
        }
    )
    if (this.publicAuthority?.structural_changes) {
      res.push(
          {
            question: 'In caso affermativo specificare:',
            response: this.publicAuthority?.structural_changes_note
          }
      )
    }
    res.push(
        {
          question: 'Sono previsti per i prossimi 3 anni piani di investimento' +
              ' in relazione ad attrezzature e/o strutture?',
          response: this.publicAuthority?.structural_investments ? EYesNo.TRUE : EYesNo.FALSE
        }
    )
    if (this.publicAuthority?.structural_investments) {
      res.push(
          {
            question: 'In caso affermativo specificare:',
            response: this.publicAuthority?.structural_investments_note
          }
      )
    }
    return res
  }

  get RiskAreasRow() {
    let res = []
    res.push(
        {
          question: 'L\'Ente autorizza il proprio personale a utilizzare mezzi propri per lo svolgimento' +
              ' di missioni / adempimento di servizio?',
          response: this.publicAuthority?.risk_area_infrastructure_use_value ? EYesNo.TRUE : EYesNo.FALSE
        }
    )
    if (this.publicAuthority?.risk_area_infrastructure_use_value) {
      res.push(
          {
            question: 'In questo caso indicare relativa percorrenza KM su base annua:',
            response: this.publicAuthority?.risk_area_infrastructure_use_km
          }
      )
    }
    res.push(
        {
          question: 'E\' di Vostro interesse valutare forme assicurative a protezione degli eventuali' +
              ' impianti fotovoltaici?',
          response: this.publicAuthority?.risk_area_photovoltaic ? EYesNo.TRUE : EYesNo.FALSE
        }
    )
    res.push(
        {
          question: 'Esistono obblighi specifici di copertura assicurativa a Vostro carico derivanti' +
              ' da accordi / convenzioni /  altro?',
          response: this.publicAuthority?.risk_area_insurance_value ? EYesNo.TRUE : EYesNo.FALSE
        }
    )
    if (this.publicAuthority?.risk_area_insurance_value) {
      res.push(
          {
            question: 'In questo caso specificare:',
            response: this.publicAuthority?.risk_area_insurance_description
          }
      )
    }
    return res
  }

  getPolicyRow(policy: any) {
    if (policy) {
      const ret = [
        {
          question: 'Validità polizza',
          response: policy.hasOwnProperty('need') ? EPolicyStatus[policy.need] : 'nessuna risposta',
          assets: []
        }, {
          question: 'Desiderio polizza',
          response: policy.hasOwnProperty('desire') ? EPolicyNeed[policy.desire] : 'nessuna risposta',
          assets: []
        },
      ]

      if (policy.assets) {
        ret.push({
          question: 'Allegati',
          response: '',
          assets: policy.assets.map((ass: any) => {
            return {
              name: ass.name,
              path: ass.file
            }
          })
        })
      }
      return ret
    }
    return [
      {
        question: 'Validità polizza',
        response: 'nessuna risposta',
        assets: []
      },
      {
        question: 'Desiderio polizza',
        response: 'nessuna risposta',
        assets: []
      }]
  }
}
