import moment from "moment";
import {EPercorsi} from "@/types";
import {Percorsi} from "@/store/storage";

class Utility {
    dottedDate(date: string) {
        return moment(date).format('DD.MM.Y HH:mm')
    }

    LocaleDate(date: string) {
        return moment(date).format('DD/MM/Y HH:mm')
    }

    mergeBykey(a1: any[], a2: any[], key: string) {
        return a1.map(itm => ({
            ...itm,
            ...a2.find((item) => (item[key] === itm[key]) && item)
        }));
    }

    onlyUnique(value: any, index: number, self: any[]) {
        return self.findIndex((item: any) => {
            return item['type'] === value['type']
        }) === index;
    }

    mergeBykeyNG(a1: any[], a2: any[], key: string) {
        const keys: any[] = [...a1, ...a2].filter(this.onlyUnique);
        return keys.map(itm => ({
            ...itm,
            ...a2.find((item) => (item[key] === itm[key]) && item)
        }));
    }

    stringToSlug(str: string) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to = "aaaaeeeeiiiioooouuuunc------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    }

    getUrlFromState(path: number, step: number){
        let stepperRoute = Percorsi[path]
        return `/dibrief/${path}_${this.stringToSlug(EPercorsi[path])}/${step}_${this.stringToSlug(stepperRoute[step].title)}`;
    }

    getStateFromUrl(url: string){
        const regex = /(dibrief\/(\d)_(.*)\/(\d)_(.*))/;
        let res = {
            path: 0, step: 0
        }
        let m;
        if ((m = regex.exec(url)) !== null) {
            // The result can be accessed through the `m`-variable.
            m.forEach((match, groupIndex) => {
                if(groupIndex == 2){
                    res.path = parseInt(match)
                }
                if(groupIndex == 4){
                    res.step = parseInt(match)
                }
            });
            return res
        } else {
            return false
        }
    }
}

export const utility = new Utility();