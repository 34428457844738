

















































import {Component, Prop, Vue} from 'vue-property-decorator';
import Dnd from "@/components/Dnd.vue";
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import InfoPoint from "@/components/InfoPoint.vue";
import CaricamentoSingoloCard from "@/components/FormCard/CaricamentoSingoloCard.vue";
import {mapActions, mapGetters} from "vuex";
import {requestApi} from "@/api/requests";
import {EAssets} from "@/types";

@Component({
  components: {
    Card,
    CardInput,
    InfoPoint,
    CaricamentoSingoloCard,
    Dnd
  },
  computed: {
    ...mapGetters('path', [
      'currentRequest',
      'getBilancioConsuntivoEntePubblicoAssets',
      'getBilancioPrevisionaleEntePubblicoAssets'
    ])
  },
  methods: {
    ...mapActions('path', [
      'refreshRequest',
    ])
  }
})
export default class CaricamentoBilanciCard extends Vue {
  private currentRequest: any;
  private isLoadingConsuntivo: boolean = false;
  private completeConsuntivo: boolean = false;
  private isLoadingPrevisionale: boolean = false;
  private completePrevisionale: boolean = false;
  private ConsuntivoFiles: File[] = []
  private PrevisionaleFiles: File[] = []

  public send(){
    if(this.currentRequest && (this.ConsuntivoFiles.length + this.PrevisionaleFiles.length > 0)) {
      return Promise.all([
        this.sendConsuntivo(this.ConsuntivoFiles),
        this.sendPrevisionale(this.PrevisionaleFiles)
      ])
    } else {
      return []
    }

  }

  sendConsuntivo(files: File[]) {
    if(this.currentRequest){
      this.isLoadingConsuntivo = true

      let uploads = files.map((file) => {
        return requestApi.requestAssetsCreate(this.currentRequest.id, file, EAssets.BILANCIO_CONSUNTIVO_ENTE_PUBBLICO).then(() => {
        })
      })

      Promise.all(uploads).then(() => {
        // @ts-ignore
        this.refreshRequest()
      }).finally(() => {
        this.completeConsuntivo = !this.completeConsuntivo
        this.isLoadingConsuntivo = false
      })

    } else {
      this.ConsuntivoFiles = files
      return null
    }
  }

  sendPrevisionale(files: File[]) {
    if(this.currentRequest) {
      this.isLoadingPrevisionale = true

      let uploads = files.map((file) => {
        return requestApi.requestAssetsCreate(this.currentRequest.id, file, EAssets.BILANCIO_PREVISIONALE_ENTE_PUBBLICO).then(() => {
        })
      })

      Promise.all(uploads).then(() => {
        // @ts-ignore
        this.refreshRequest()
      }).finally(() => {
        this.completePrevisionale = !this.completePrevisionale
        this.isLoadingPrevisionale = false
      })

    } else {
      this.PrevisionaleFiles = files;
      return null
    }
  }

  handleFileRemovePrevisionale(id: string) {

    if(this.currentRequest) {
      this.isLoadingPrevisionale = true
      requestApi.requestAssetsDestroy(this.currentRequest.id, id).then(() => {
        // @ts-ignore
        this.refreshRequest()
      }).finally(() => {
        this.isLoadingPrevisionale = true
      })
    } else {
      this.PrevisionaleFiles.splice(parseInt(id), 1)
    }
  }

  handleFileRemoveConsuntivo(id: string) {

    if(this.currentRequest) {
      this.isLoadingConsuntivo = true
      requestApi.requestAssetsDestroy(this.currentRequest.id, id).then(() => {
        // @ts-ignore
        this.refreshRequest()
      }).finally(() => {
        this.isLoadingConsuntivo = false
      })
    } else {
      this.ConsuntivoFiles.splice(parseInt(id), 1)
    }
  }

  get getBilancioConsuntivo(){
    if(this.currentRequest){
      // @ts-ignore
      return this.getBilancioConsuntivoEntePubblicoAssets
    } else {
      return this.ConsuntivoFiles.map((file, index)=>{
        return {
          path: '', name: file.name, id: index
        }
      })
    }
  }

  get getBilancioPrevisionale(){
    if(this.currentRequest){
      // @ts-ignore
      return this.getBilancioPrevisionaleEntePubblicoAssets
    } else {
      return this.PrevisionaleFiles.map((file, index)=>{
        return {
          path: '', name: file.name, id: index
        }
      })
    }
  }
}

