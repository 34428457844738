






















import {Component, Prop, Vue} from 'vue-property-decorator';
import ErrorTp from "@/components/ErrorTp.vue";

@Component({
  components:{
    ErrorTp
  }
})
export default class CardInput extends Vue {
  @Prop() private readonly label!: string
  @Prop() private readonly pre_title_icon!: string
  @Prop() private readonly show_error!: boolean | null;
  @Prop() private readonly error!: string;
  @Prop() private readonly value!: any;
  @Prop() private readonly readonly !: boolean;
  @Prop({
    default: () => {
      return {}
    }
  }) private readonly inputProps!: any;
  @Prop({default: true}) private readonly border!: boolean;

  get right(){
    return this.$attrs.right || '10px'
  }

}
