<template>
    <div id="app">
        <!--    <DeleteUser></DeleteUser>-->
        <router-view name="nav"></router-view>
        <div class="position-relative d-flex justify-content-center">
            <transition name="fade">
                <router-view/>
            </transition>
            <HelpModal></HelpModal>
        </div>
    </div>
</template>

<script>
export default{
  mounted() {
    // check if user is logged
    (function(d, m){
      const kommunicateSettings = {
        "appId": "23aacd699a06fcdd1f4c072ccaf804257",
        "popupWidget": true,
        "automaticChatOpenOnNavigation": true,
        "onInit": function () {
          Kommunicate.displayKommunicateWidget(false);
        }};
      const s = document.createElement("script");
      s.type = "text/javascript"; s.async = true;
      s.src = "https://widget.kommunicate.io/v2/kommunicate.app";
      const h = document.getElementsByTagName("head")[0];
      h.appendChild(s);
      window.kommunicate = m; m._globals = kommunicateSettings;
    })(document, window.kommunicate || {});
  }
}

</script>

<style lang="scss">
@import '@/assets/scss/app.scss';
</style>

