import { render, staticRenderFns } from "./InsuraceContractsTableCard.vue?vue&type=template&id=622e6464&scoped=true&"
import script from "./InsuraceContractsTableCard.vue?vue&type=script&lang=ts&"
export * from "./InsuraceContractsTableCard.vue?vue&type=script&lang=ts&"
import style0 from "./InsuraceContractsTableCard.vue?vue&type=style&index=0&id=622e6464&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "622e6464",
  null
  
)

export default component.exports