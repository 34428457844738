




















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Step extends Vue {
  @Prop() private readonly index!: number
  @Prop({default: true}) private readonly first!: boolean
  @Prop({default: false}) private readonly last!: boolean
  @Prop() private readonly completed!: boolean
}
