


















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Stepper from "@/components/Stepper.vue";
import RequireAuth from "@/components/RequireAuth.vue";
import {mapActions, mapGetters} from "vuex";
import {utility} from '@/utility';
import {EventBus} from "@/EventBus";
import {requestApi} from "@/api/requests";

@Component({
  components: {
    Stepper,
  },
  computed: {
    ...mapGetters({
      'isAuth': 'user/isAuth',
      'currentRequest': 'path/currentRequest',
      'stepperRoute': 'path/stepperRoute',
      'step': 'path/step'
    })
  }
})
export default class StepDispathcer extends Vue {

  private title: string = '';
  private isAuth!: boolean;
  private currentRequest: any;
  private stepperRoute: any;
  private step: any;

  created() {
    let res = utility.getStateFromUrl(this.$route.path);
    if (res) {
      this.$store.dispatch('path/setStepper', res)
    }
    EventBus.$on('ForwardIfComplete', () => {
      if (this.currentRequest.step == this.step) {
        this.$store.dispatch('path/updateRequest', {step: this.step + 1}).then(() => {
          if (this.step < this.stepperRoute.length) {
            //@ts-ignore
            let url = utility.getUrlFromState(this.currentRequest.path, this.step + 1)
            this.$router.push(url)
          }
        })
      } else {
        if (this.step < this.stepperRoute.length) {
          //@ts-ignore
            let url = utility.getUrlFromState(this.currentRequest.path, this.step + 1)
            this.$router.push(url)
        }
      }
    })
  }

  beforeDestroy() {
    EventBus.$off('ForwardIfComplete')
  }

  @Watch('$route')
  onRouterChange(val: any) {
    let res = utility.getStateFromUrl(this.$route.path);
    if (res) {
      this.$store.dispatch('path/setStepper', res)
    }
  }

  get Component() {
    if (this.isAuth) {
      if (this.currentRequest || this.$store.getters['path/isFirst']) {
        const state = this.$store.getters['path/getState']
        if (state) {
          this.title = state.title
          //@ts-ignore
          document.querySelector('html').scrollTop = 0
          if (this.$gtm)
            this.$gtm.trackView(state.title, state.component.name);
          return state.component
        }
      } else {
        this.$router.push({name: 'Account'})
      }
    }
  }
}
