


























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Loading from "@/components/Loading.vue";
import {DetailAssetsInterface} from "@/types";

@Component({
    components: {
        Loading,
    }
})
export default class Dnd extends Vue {
    @Prop({default: false}) private readonly multiple!: boolean;
    @Prop({default: false}) private readonly readonly !: boolean;
    @Prop({
        default: () => {
            return []
        }
    }) private readonly files!: DetailAssetsInterface[];
    @Prop({default: '*'}) private readonly accept!: string;
    @Prop({default: false}) private readonly isLoading!: boolean;
    @Prop() private readonly triggerComplete!: boolean;

    private upload: File[] = []

    open() {
        if(this.readonly) return;
        (this.$refs.file as HTMLBaseElement).click()
    }

    send() {
        this.$emit('send', this.upload)
    }

    @Watch('triggerComplete')
    clean(){
        // @ts-ignore
        this.$refs.file.value = ''
        this.upload.splice(0)
    }

    append(file: File) {
        if(this.readonly) return;
        this.upload.push(file)
    }

    onDragOver() {
        // console.log('drag over')
    }

    onChange(ev: Event) {
        if(this.readonly) return;
        const files = (ev.target as HTMLInputElement).files || (ev as DragEvent).dataTransfer?.files;
        if (!files?.length) return;

        for (let i = 0; i < files.length; i++) {
            this.append(files[i])
        }

        this.send();
    }

    onDrop(ev: DragEvent) {
        if(this.readonly) return;
        if (ev.dataTransfer) {

            if (ev.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (ev.dataTransfer.items[i].kind === 'file') {
                        const file = ev.dataTransfer.items[i].getAsFile();
                        if (file) {
                            this.append(file);
                        }
                    }
                }
            } else {
                // Use DataTransfer interface to access the file(s)
                for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                    this.append(ev.dataTransfer.files[i]);
                }
            }
            this.send();
        }
    }
}
