import { render, staticRenderFns } from "./ClaimsNotCoveredTableCard.vue?vue&type=template&id=2471e98e&scoped=true&"
import script from "./ClaimsNotCoveredTableCard.vue?vue&type=script&lang=ts&"
export * from "./ClaimsNotCoveredTableCard.vue?vue&type=script&lang=ts&"
import style0 from "./ClaimsNotCoveredTableCard.vue?vue&type=style&index=0&id=2471e98e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2471e98e",
  null
  
)

export default component.exports