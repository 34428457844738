























































import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import InfoPoint from "@/components/InfoPoint.vue";
import {EventBus} from "@/EventBus";
import {helper} from "@/helper";
import {mapGetters} from "vuex";

@Component({
    components: {
        Card,
        CardInput,
        InfoPoint
    },
    computed: {
        ...mapGetters('path', [
            'currentRequest',
        ])
    },
})
export default class InfoAziendaliCard extends Vue {
    @Prop() private readonly showError!: boolean;

    private budgetCurrent: number | null = null;
    private budgetEstimated: number | null = null;
    private helper = helper

    private fa_show_error: boolean = true;
    private fp_show_error: boolean = true;
    private currentRequest: any;

    showHelp($event: any) {
        EventBus.$emit('showHelp', $event)
    }

    created() {
        if (this.currentRequest) {
            this.budgetCurrent = this.currentRequest.company.budgetCurrent
            this.budgetEstimated = this.currentRequest.company.budgetEstimated
            this.validate()
        }
    }

    validate() {
        this.fa_show_error = !this.budgetCurrent;
        this.fp_show_error = !this.budgetEstimated;

        this.$emit('valid', !(this.fa_show_error || this.fp_show_error));

        this.$emit('input', {
            budgetCurrent: this.budgetCurrent,
            budgetEstimated: this.budgetEstimated,
        })
    }
}
