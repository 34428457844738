






import {Component, Prop, Vue} from 'vue-property-decorator';
import Step from "@/components/Step.vue";
import {userAPI} from "@/api/users";

@Component({
  components: {
    Step
  }
})
export default class DeleteUser extends Vue {

  dropuser(){
    userAPI.userDestroy().then(()=>{
      //todo: settare lo store
    })
  }
}
