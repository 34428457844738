import axios, {AxiosResponse} from "axios";
import {
    PublicAuthorityInterface,
    RequestsCreateInterface,
    RequestsQuestionUpdateInterface, RequestsUpdateInterface, UsersUpdateInterface
} from "@/api/RequestsInterface";

class Requests {
    private base_url = process.env.VUE_APP_BACKEND_URL

    public async requestsRetrieve(): Promise<AxiosResponse> {
        return axios.get(`${this.base_url}/requests/`)
    }

    public async usersRetrieve(): Promise<AxiosResponse> {
        return axios.get(`${this.base_url}/user/`)
    }

    public async requestCreate(data: RequestsCreateInterface): Promise<AxiosResponse> {
        return axios.post(`${this.base_url}/requests/`, data)
    }

    public async requestRetrieve(uuid: string): Promise<AxiosResponse> {
        return axios.get(`${this.base_url}/requests/${uuid}/`)
    }

    public async requestUpdate(uuid: string, data: RequestsUpdateInterface): Promise<AxiosResponse> {
        return axios.put(`${this.base_url}/requests/${uuid}/`, data)
    }

    public async userUpdate(uuid: string, data: UsersUpdateInterface): Promise<AxiosResponse> {
        return axios.put(`${this.base_url}/user/${uuid}/`, data)
    }

    public async requestQuestionBlocksRetrieve(uuid: string): Promise<AxiosResponse> {
        return axios.get(`${this.base_url}/requests/${uuid}/question_blocks/`)
    }

    public async requestQuestionsSetAnswer(uuid: string, uuid_q: string, data: RequestsQuestionUpdateInterface): Promise<AxiosResponse> {
        return axios.put(`${this.base_url}/requests/${uuid}/questions/${uuid_q}/set_answer/`, data)
    }

    public async requestAnswersRetrive(uuid: string): Promise<AxiosResponse> {
        return axios.get(`${this.base_url}/requests/${uuid}/answers/`)
    }

    public async requestAssetsDestroy(uuid_r: string, uuid_a: string): Promise<AxiosResponse> {
        return axios.delete(`${this.base_url}/requests/${uuid_r}/assets/${uuid_a}/`)
    }

    public async requestAssetsTypeDestroy(uuid: string, type: number): Promise<AxiosResponse> {
        return axios.delete(`${this.base_url}/requests/${uuid}/assets/delete_assets/`, {data: {type: type}})
    }

    public async requestAssetsCreate(uuid_r: string, file: File, type: number, policyId?: string): Promise<AxiosResponse> {
        const data = new FormData()
        data.append('type', `${type}`);

        if(policyId){
            data.append('policy_id', `${policyId}`);
        }

        data.append('file', file)
        return axios.post(`${this.base_url}/requests/${uuid_r}/assets/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    public async requestAssetsRetrieve(uuid: string): Promise<AxiosResponse> {
        return axios.get(`${this.base_url}/requests/${uuid}/assets/`)
    }

    public async requestVehiclesRetrieve(uuid: string): Promise<AxiosResponse> {
        return axios.get(`${this.base_url}/requests/${uuid}/vehicles/`)
    }

    public async requestVehiclesCreate(uuid: string, data: any): Promise<AxiosResponse> {
        return axios.post(`${this.base_url}/requests/${uuid}/vehicles/`, data)
    }

    public async requestVehiclesUpdate(uuid_r: string, uuid_v: string, data: any): Promise<AxiosResponse> {
        return axios.put(`${this.base_url}/requests/${uuid_r}/vehicles/${uuid_v}/`, data)
    }

    public async requestVehiclesDestroy(uuid_r: string, uuid_v: string): Promise<AxiosResponse> {
        return axios.delete(`${this.base_url}/requests/${uuid_r}/vehicles/${uuid_v}/`)
    }

    public async requestPoliciesRetrieve(uuid: string): Promise<AxiosResponse> {
        return axios.get(`${this.base_url}/requests/${uuid}/policies/`)
    }

    public async requestPolicyAnswersRetrive(uuid: string): Promise<AxiosResponse> {
        return axios.get(`${this.base_url}/requests/${uuid}/policy_answers/`)
    }

    public async requestPolicySetAnswer(uuid: string, uuid_p: string, data: any): Promise<AxiosResponse> {
        return axios.put(`${this.base_url}/requests/${uuid}/policies/${uuid_p}/set_answer/`, data)
    }

    public async requestPublicAuthorityRetrieve(uuid: string): Promise<AxiosResponse> {
        return axios.get(`${this.base_url}/requests/${uuid}/public-authority/`)
    }

    public async requestPublicAuthorityCreate(uuid: string, data: PublicAuthorityInterface): Promise<AxiosResponse> {
        return axios.post(`${this.base_url}/requests/${uuid}/public-authority/`, data)
    }

    public async requestPublicAuthorityUpdate(uuid_r: string, data: PublicAuthorityInterface): Promise<AxiosResponse> {
        return axios.put(`${this.base_url}/requests/${uuid_r}/public-authority/`, data)
    }

}

export const requestApi = new Requests()