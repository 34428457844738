














import {Component, Prop, Vue} from 'vue-property-decorator';
import CardInput from "@/components/CardInput.vue";
import InfoAziendaliCard from "@/components/FormCard/InfoAziendaliCard.vue";
import BilancioCard from "@/components/FormCard/BilancioCard.vue";
import OrganicoCard from "@/components/FormCard/OrganicoCard.vue";
import CheckBoxCard from "@/components/FormCard/CheckBoxCard.vue";
import {requestApi} from "@/api/requests";
import {mapGetters} from "vuex";
import {EStatus} from "@/types";

@Component({
  components: {
    BilancioCard,
    CardInput,
    CheckBoxCard
  },
  computed: {
    ...mapGetters({
      'currentRequest': 'path/currentRequest',
    })
  },
})


export default class End extends Vue {
  private currentRequest: any;

  created() {
    if (this.currentRequest) {
      requestApi.requestUpdate(this.currentRequest.id, {status: EStatus.Nuova})
    }
  }
}
