



















































import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import InfoPoint from "@/components/InfoPoint.vue";
import Dnd from "@/components/Dnd.vue";
import {requestApi} from "@/api/requests";
import {mapActions, mapGetters} from "vuex";
import {RealEstateTypeSelect} from "@/types";
import {EventBus} from "@/EventBus";
import BDate from "@/views/b-date.vue";
import {PublicAuthorityInsuranceContractsInterface, PublicAuthorityInterface} from "@/api/RequestsInterface";

@Component({
    components: {
        BDate,
        Card,
        CardInput,
        InfoPoint,
    },
    computed: {
        ...mapGetters('path', [
            'publicAuthority'
        ])
    },
    methods: {
        ...mapActions('path', [
            'refreshRequest'
        ])
    }
})
export default class InsuraceContractsTableCard extends Vue {

    @Prop({default:false}) private readonly readonly!: boolean

    private publicAuthority?: PublicAuthorityInterface;

    private rows: PublicAuthorityInsuranceContractsInterface[] = []

    private fields = [
        {key: 'n', label: ''},
        {key: 'policy_number', label: 'Polizza n.'},
        {key: 'company', label: 'Compagnia (Delegataria)'},
        {key: 'subject', label: 'Oggetto - Ramo'},
        {key: 'expiration', label: 'Scadenza proroga / rinnovo'},
        {key: 'insurance_premium', label: 'Premi annui imponibili presuntivi'},
        {key: 'delete', label: ''}
    ]

    private description: string = ''

    FormatDate(date: string) {
        return new Date(date).toISOString()
    }

    addRow() {
        if(this.readonly) return;
        this.rows.push({policy_number: '', company: '', subject: '', expiration: '', insurance_premium: 0},)
    }

    async removeRow(row: any) {
        this.rows.splice(row.index, 1)
    }

    created(){
      if (this.publicAuthority){
        this.rows = this.publicAuthority?.insurance_contracts || [];
      }
    }

    public getData(){
      return this.rows
    }

    isValid(){
        return !this.rows.some((r)=>{
            return !r.policy_number || !r.company || !r.subject ||r.expiration == null || !r.insurance_premium
        })
    }

    setChange(row: any) {
        Vue.set(row.item, 'changed', true)
    }

}
