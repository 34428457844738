import axios, {AxiosResponse} from "axios";

class UsersAPI {
    private base_url = process.env.VUE_APP_BACKEND_URL

    public signUp(email: string): Promise<AxiosResponse> {
        return axios.post(`${this.base_url}/auth/`, {
            email
        }, {headers: {Authorization: null}})
    }

    public sendAuthLink(email: string): Promise<AxiosResponse> {
        return axios.post(`${this.base_url}/auth/`, {
            email
        }, {headers: {Authorization: null}})
    }

    public async loginWithToken(resetToken: string) {
        return axios.get(`${this.base_url}/auth/${resetToken}/login/`, {headers: {Authorization: null}})
    }

    public logout() {
        return axios.post(`${this.base_url}/auth/logout/`, {
        })
    }

    public userUpdate(data: any): Promise<AxiosResponse> {
        return axios.put(`${this.base_url}/user/me/`, data)
    }

    public userDestroy(): Promise<AxiosResponse> {
        return axios.delete(`${this.base_url}/user/me/`)
    }

    public currentUser() {
        return axios.get(`${this.base_url}/user/me/`)
    }

    public async usersRequests() {
        return axios.get(`${this.base_url}/user/me/requests/`)
    }

}

export const userAPI = new UsersAPI()