









import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class Page404 extends Vue {

}
