class EnvHelper {

    public isProd(){
        return process.env.NODE_ENV == 'production'
    }

    public isDev(){
        return process.env.NODE_ENV == 'development'
    }

    public isTest(){
        return process.env.NODE_ENV == 'test'
    }

    public siteUrl(){
        return process.env.VUE_APP_SITE_URL
    }

    public GTMId(){
        return process.env.VUE_APP_GTM_ID
    }
}

export const envHelper = new EnvHelper()
