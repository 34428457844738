









































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import Dnd from "@/components/Dnd.vue";
import InfoPoint from "@/components/InfoPoint.vue";
import {EventBus} from "@/EventBus";
import {EAssets, EPolicyNeed, PolicyNeedSelect, PolicyStatusSelect} from "@/types";
import {requestApi} from "@/api/requests";
import {mapActions, mapGetters} from "vuex";

@Component({
  components: {
    Card,
    CardInput,
    Dnd,
    InfoPoint
  },
  computed: {
    ...mapGetters('path', [
      'currentRequest',
      'getMassiveAssets'
    ])
  },
  methods: {
    ...mapActions('path', [
      'refreshPolicyAnswers',
    ])
  }
})
export default class CaricamentoSingoloCard extends Vue {
  @Prop() private readonly subtitle!: string;
  @Prop() private readonly value!: boolean;
  @Prop({default: false}) private readonly showError!: boolean;
  @Prop() private readonly titolo!: string;
  @Prop() private readonly showUpload!: boolean;
  @Prop({
    default: () => {
      return {
        need: null,
        desire: null,
        assets: []
      }
    }
  }) private readonly policy_answer!: any;
  @Prop() private readonly policyID!: string;

  private currentRequest: any;

  private expand: boolean = false
  private isLoading: boolean = false
  private complete: boolean = false

  get showPolicyError() {
    return this.showError && !this.PolicyAssets?.length
  }

  get showDesireError() {
    return this.showError && this.Desire == null
  }

  get showNeedError() {
    return this.showError && this.Need == null
  }

  get Need() {
    return this.policy_answer.need
  }

  get Desire() {
    return this.policy_answer.desire
  }

  @Watch('showUpload')
  @Watch('policy_answer')
  validate() {
    this.$emit('input', this.Need !== null && this.Desire !== null && (!this.showUpload || (this.PolicyAssets && !this.PolicyAssets.length)))
  }

  created() {
    this.validate()
  }

  set Need(value: number) {


    requestApi.requestPolicySetAnswer(this.currentRequest.id,  this.policyID, {
      need: value, policy: this.policyID,
    }).then(()=>{
      Vue.set(this.policy_answer, 'need', value)
      this.validate()
    })
  }

  set Desire(value: number) {

    requestApi.requestPolicySetAnswer(this.currentRequest.id,  this.policyID, {
      desire: value, policy: this.policyID,
    }).then(()=>{
      Vue.set(this.policy_answer, 'desire', value)
      this.validate()
    })
  }

  private desiderio_options = [
    {value: null, text: 'Seleziona', disabled: false}, ...PolicyNeedSelect]


  private validita_options: any[] = [
    {value: null, text: 'Seleziona', disabled: true}, ...PolicyStatusSelect]

  get collapseText() {
    return this.expand ? 'Vedi meno' : 'Scopri di più'
  }

  showHelp(event: any) {
    EventBus.$emit('showHelp', event)
  }

  send(files: File[]) {
    //se la polizza non esiste allora prima la creo
    this.isLoading = true

    let uploads = files.map(async (file) => {
      return requestApi.requestAssetsCreate(this.currentRequest.id, file, EAssets.POLIZZA, this.policyID).then(() => {
      })
    })

    Promise.all(uploads).then(() => {
      // @ts-ignore
      this.refreshPolicyAnswers()
    }).finally(() => {
      this.complete = !this.complete
      this.isLoading = false
    })
  }

  handleFileRemove(id: string) {
    this.isLoading = true
    requestApi.requestAssetsDestroy(this.currentRequest.id, id).then(() => {
      // @ts-ignore
      this.refreshPolicyAnswers()
    }).finally(() => {
      this.isLoading = false
    })
  }

  get PolicyAssets() {
    return this.policy_answer.assets
  }

}

