


































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import Multiselect from "vue-multiselect";
import {mapGetters} from "vuex";
import {userAPI} from "@/api/users";

@Component({
    components: {
        Card,
        CardInput,
        Multiselect,
    },
    computed: {
        ...mapGetters('user', [
            'user',
            'userEmail',
            'userFirstName',
            'userLastName',
            'userRole',
        ])
    },
})
export default class InfoUserCard extends Vue {
    @Prop() private readonly showError!: boolean;

    private first_name = ''
    private last_name = ''
    private role = ''

    get FirstName() {
        //@ts-ignore
        return this.userFirstName
    }

    set FirstName(value: string) {
        this.first_name = value
    }

    get LastName() {
        //@ts-ignore
        return this.userLastName
    }

    set LastName(value: string) {
        this.last_name = value
    }

    get Role() {
        //@ts-ignore
        return this.userRole
    }

    set Role(value: string) {
        this.role = value
    }

    validate(){
      this.$emit('valid', !!this.first_name && !!this.last_name && !!this.role)
    }

    created(){
      this.first_name = this.FirstName
      this.last_name = this.LastName
      this.role = this.Role
      this.validate()
    }

    public save() {
        // @ts-ignore
        return userAPI.userUpdate( {
            first_name: this.first_name,
            last_name: this.last_name,
            role: this.role
        }).then((res: any) => {
            this.$store.dispatch('user/setUser', res.data)
        })
    }

}
