
































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from "vuex";
import {userAPI} from "@/api/users";

@Component({
  components: {},
  computed: {
    ...mapGetters('user', [
      'user',
      'userEmail',
      'userFirstName',
      'userLastName',
      'userRole',
    ])
  },
})
export default class Account extends Vue {

  private email = ''
  private old_email = ''
  private mail_editing = false

  private name = ''
  private old_name = ''
  private name_editing = false

  private lastname = ''
  private old_lastname = ''
  private lastname_editing = false

  private role = ''
  private old_role = ''
  private role_editing = false

  private user_editing = false

  get Email() {
    //@ts-ignore
    return this.userEmail
  }

  set Email(value: string) {
    this.email = value
  }

  get FirstName() {
    //@ts-ignore
    return this.userFirstName
  }

  set FirstName(value: string) {
    this.name = value
  }


  get LastName() {
    //@ts-ignore
    return this.userLastName
  }

  set LastName(value: string) {
    this.lastname = value
  }

  get Role() {
    //@ts-ignore
    return this.userRole
  }

  set Role(value: string) {
    this.role = value
  }

  async deleteUser() {
    //@ts-ignore
    if (this.user) {
      //@ts-ignore
      await userAPI.usersDestroy(this.user.id)
      await this.$store.dispatch('user/reset')
      await this.$router.replace({name: 'Start'})
    }
  }

  private saveField(field: string, value: string) {
    let data: any = {}
    data[field] = value
    // @ts-ignore
    userAPI.userUpdate(data).then((res: any) => {
      this.$store.dispatch('user/setUser', res.data)
    })
  }

  private focus(key: string) {
    // @ts-ignore
    this.$nextTick(() => this.$refs[key].focus())
  }

}
