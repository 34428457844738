

































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Card extends Vue {
  @Prop() private readonly title!: string
  @Prop() private readonly pre_title_icon!: string
}
