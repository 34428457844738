



























































import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import InfoPoint from "@/components/InfoPoint.vue";
import {mapActions, mapGetters} from "vuex";
import BDate from "@/views/b-date.vue";
import {
  PublicAuthorityClaimsNotCoveredItemInterface,
  PublicAuthorityInterface
} from "@/api/RequestsInterface";

@Component({
    components: {
        BDate,
        Card,
        CardInput,
        InfoPoint,
    },
    computed: {
        ...mapGetters('path', [
            'publicAuthority'
        ])
    },
    methods: {
        ...mapActions('path', [
            'refreshRequest',
        ])
    }
})
export default class ClaimsNotCoveredTableCard extends Vue {

    @Prop({default:false}) private readonly readonly!: boolean

    private publicAuthority?: PublicAuthorityInterface;
    private rows: PublicAuthorityClaimsNotCoveredItemInterface[] = []
    private claims_not_covered_description: string = ''

    created(){
      if(this.publicAuthority){
        this.rows = this.publicAuthority.claims_not_covered
        this.claims_not_covered_description = this.publicAuthority.claims_not_covered_description
      }
    }

    private fields = [
        {key: 'event', label: 'Evento'},
        {key: 'date', label: 'Data'},
        {key: 'total_value', label: 'Importo liquidato'},
        {key: 'total_reserved', label: 'Importo Riservato'},
        {key: 'note', label: 'Note'},
        {key: 'delete', label: ''}
    ]

    FormatDate(date: string) {
        return new Date(date).toISOString()
    }


    addRow() {
        if(this.readonly) return;
        this.rows.push({event: '', date: null, total_value: 0, total_reserved: 0, note: ''})
    }


    async removeRow(row: any) {
      this.rows.splice(row.index, 1)
    }


    public getData(){
      return {
        'claims_not_covered': this.rows,
        'claims_not_covered_description': this.claims_not_covered_description
      }
    }

    isValid(){
        return !this.rows.some((r: any)=>{
            return !r.insurance || !r.claims_number || !r.claims_closed || !r.total_value || !r.total_reserved
        })
    }

}
