import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import OnoffToggle from 'vue-onoff-toggle'


import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import HelpModal from "@/components/HelpModal.vue";
import DeleteUser from "@/components/DeleteUser.vue";
import VueAxios from "vue-axios";
import axios from "axios";
import Loading from "@/components/Loading.vue";
import {userAPI} from "@/api/users";


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueAxios, axios)

Vue.use(OnoffToggle)
Vue.component('HelpModal', HelpModal)
Vue.component('DeleteUser', DeleteUser)
Vue.component('Loading', Loading)

Vue.config.productionTip = true

if(localStorage.getItem('user_token')){
    axios.defaults.headers.common['Authorization'] = `Token ${localStorage.getItem('user_token')}`;
}

userAPI.currentUser().then((response)=>{
    return store.dispatch('user/setUser', response.data)
}).catch(async () => {
    await store.dispatch('user/setToken', null)
    return store.dispatch('user/setUser', null)
}).finally(()=>{
    new Vue({
        store,
        router,
        render: h => h(App),
        created() {

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            const resetToken = urlParams.get('reset_token'); // "value1"

            if(resetToken){
                userAPI.loginWithToken(resetToken.toString()).then(
                    async (response) => {
                        await this.$store.dispatch('user/setToken', response.data.token)
                        return this.$store.dispatch('user/setUser', response.data)
                    }
                ).finally(()=>{
                    this.$mount('#app')
                })
            } else {
                this.$mount('#app')
            }
        },
    })
})


