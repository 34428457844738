





















































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import Multiselect from "vue-multiselect";
import {
  ETarget,
  LegalFormSelect,
  MarketSelect,
  PuclicLegalFormSelect,
  SectorSelect
} from "@/types";
import {mapGetters} from "vuex";

@Component({
  components: {
    Card,
    CardInput,
    Multiselect,
  },
  computed: {
    ...mapGetters('path', [
      'currentRequest',
    ])
  },
})
export default class InfoAziendaliCard extends Vue {
  @Prop() private readonly showError!: boolean;

  ETarget = ETarget

  public get isEntePubblico() {
    return this.target == ETarget["ente pubblico"]
  }

  public get isLiberoProfessionista() {
    return this.target == ETarget["libero professionista"]
  }

  get LegalFormOptions() {
    if (this.isEntePubblico) {
      return [{value: null, text: 'Seleziona', disabled: true}, ...PuclicLegalFormSelect]
    }
    return [{value: null, text: 'Seleziona', disabled: true}, ...LegalFormSelect]
  }

  get LegalFormLabel() {
    if (this.isEntePubblico) {
      return 'Tipologia'
    }
    return 'Forma societaria'
  }

  get NameFormLabel() {
    if (this.isEntePubblico) {
      return 'Nome Ente'
    }
    return 'Nome'
  }

  private sector_options = [
    {value: null, text: 'Seleziona', disabled: true}, ...SectorSelect]

  private market_options = MarketSelect
  private currentRequest: any;

  created() {
    if (this.currentRequest) {
      this.businessName = this.currentRequest.company.businessName
      this.target = this.currentRequest.company.target

      if (this.currentRequest.company.target == ETarget["ente pubblico"]) {
        this.legalForm = null
        this.market = []
        this.vatCode = null
        this.sector = null
        this.ateco = null
        this.legalForm = null
      } else {

        if (this.currentRequest.company.target == ETarget["libero professionista"]) {
          this.legalForm = null
        } else {
          this.legalForm = this.currentRequest.company.legalForm
        }

        this.market = this.market_options.filter((opt) => {
          return this.currentRequest.company.market.includes(opt.value)
        })
        this.vatCode = this.currentRequest.company.vatCode
        this.sector = this.currentRequest.company.sector
        this.ateco = this.currentRequest.company.ateco
      }

      this.validate()
    }
  }

  private target: number = ETarget['società'];
  private businessName: string | null = null;
  private vatCode: string | null = null;
  private sector: number | null = null;
  private legalForm: number | null = null;
  private ateco: string | null = null;
  private market: any[] = [];

  private businessName_show_error: boolean = true;
  private vatCode_show_error: boolean = true;
  private legalForm_show_error: boolean = true;
  private sector_show_error: boolean = true;
  private ateco_show_error: boolean = true;
  private market_show_error: boolean = true;

  Variant(val: number) {
    if (val === this.target) {
      return 'secondary3'
    }
    return 'outline-primary'
  }

  validate() {
    this.businessName_show_error = !this.businessName
    this.vatCode_show_error = !this.vatCode
    this.legalForm_show_error = this.legalForm == null
    this.sector_show_error = this.sector == null
    this.ateco_show_error = !this.ateco
    this.market_show_error = this.market.length == 0

    // nel caso di ente pubblico resetto i campi non visibili
    if (this.isEntePubblico) {
      this.vatCode_show_error = false
      this.sector_show_error = false
      this.ateco_show_error = false
      this.market_show_error = false
      this.legalForm_show_error = false
    } else if (this.isLiberoProfessionista) {
      this.legalForm_show_error = false
    }

    this.$emit('valid',
        !(this.businessName_show_error ||
            this.vatCode_show_error ||
            this.legalForm_show_error ||
            this.sector_show_error ||
            this.ateco_show_error ||
            this.market_show_error)
    )
    this.$emit('input', {
      target: this.target,
      businessName: this.businessName,
      vatCode: this.vatCode,
      sector: this.sector,
      legalForm: this.legalForm,
      ateco: this.ateco,
      market: this.market
    })
  }

}
