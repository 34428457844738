

















































import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import InfoPoint from "@/components/InfoPoint.vue";
import {mapActions, mapGetters} from "vuex";
import BDate from "@/views/b-date.vue";
import {PublicAuthorityClaimsCoveredItemInterface, PublicAuthorityInterface} from "@/api/RequestsInterface";

@Component({
    components: {
        BDate,
        Card,
        CardInput,
        InfoPoint,
    },
    computed: {
        ...mapGetters('path', [
            'publicAuthority'
        ])
    },
    methods: {
        ...mapActions('path', [
            'refreshRequest',
        ])
    }
})
export default class ClaimsCoveredTableCard extends Vue {

    @Prop({default:false}) private readonly readonly!: boolean

    private publicAuthority?: PublicAuthorityInterface;
    private claims_covered: PublicAuthorityClaimsCoveredItemInterface[] = []
  

    private fields = [
        {key: 'insurance', label: 'Polizza'},
        {key: 'claims_number', label: 'Sinistri denunciati n.'},
        {key: 'claims_closed', label: 'Di cui chiusi senza sequito n.'},
        {key: 'total_value', label: 'Importo liquidato'},
        {key: 'total_reserved', label: 'Importo Riservato'},
        {key: 'delete', label: ''}
    ]

    created(){
        if(this.publicAuthority){
          this.claims_covered = this.publicAuthority.claims_covered
        }
    }


    addRow() {
        if(this.readonly) return;
        this.claims_covered.push({insurance: '', claims_number: 0, claims_closed: 0, total_value: 0, total_reserved: 0})
    }


    async removeRow(row: any) {
        this.claims_covered.splice(row.index, 1)
    }


    public getData(){
      return this.claims_covered
    }


    isValid(){
        return !this.claims_covered.some((r: any)=>{
            return !r.insurance || !r.claims_number
        })
    }

}
