

















import {Component, Prop, Vue} from 'vue-property-decorator';
import Step from "@/components/Step.vue";
import Radio from "@/components/Radio.vue";
import {PolicyLoadSelect} from "@/types";

@Component({
  components: {
    Radio,
  }
})
export default class RadioSelect extends Vue {
  @Prop() private readonly value!:number;

  private options = PolicyLoadSelect

}
