import {Module} from "vuex";
import {userAPI} from "@/api/users";
import {EStatus} from "@/types";
import axios from "axios";


interface User {
    "id": number,
    "first_name": string,
    "last_name": string,
    "email": string,
    "role": string,
    "is_staff": boolean
}

interface UserModuleInterface {
    user: null | User,
    completed_requests: [],
    pending_requests: [],
    menuOpened: boolean
}


export const userModule: Module<UserModuleInterface, any> = {
    namespaced: true,
    state: {
        user: null,
        completed_requests: [],
        pending_requests: [],
        menuOpened: false
    },
    mutations: {
        setToken(state, token) {
            axios.defaults.headers.common['Authorization'] = `Token ${token}`;
            localStorage.setItem('user_token', token);
        },
        setUser(state, user) {
            if (user && user.email != null) {
                state.user = user;
                localStorage.setItem('user_mail', user.email);

                setTimeout(function(){
                    // @ts-ignore
                    window.kommunicate.updateUser({
                        "displayName":  user.displayName,
                        "email": user.email,
                    })
                    // @ts-ignore
                    window.kommunicate.displayKommunicateWidget(true)
                }, 3000);
            } else {
                state.user = null;
                state.completed_requests = []
                state.pending_requests = []
                localStorage.removeItem('user_mail');
            }
        },
        setRequests(state, requests) {
            state.completed_requests = requests.filter((r: any) => {
                return r.status !== EStatus["Da completare"]
            });
            state.pending_requests = requests.filter((r: any) => {
                return r.status == EStatus["Da completare"]
            });
        },
        setCompletedRequests(state, cr) {
            state.completed_requests = cr
        },
        setPendingRequests(state, cr) {
            state.completed_requests = cr
        },
        toggleMenu(state) {
            state.menuOpened = !state.menuOpened
        },
        closeMenu(state) {
            state.menuOpened = false
        }
    },
    actions: {
        setUser({commit}, user) {
            commit('setUser', user)
        },
        setToken({commit}, token) {
            commit('setToken', token)
        },
        empty({commit}) {
            commit('setUser', null)
        },
        fetchRequests({commit, state}, user) {
            if (state.user != null) {
                // @ts-ignore
                return userAPI.usersRequests().then((res) => {
                    commit('setRequests', res.data)
                }).catch(() => {
                    commit('setCompletedRequests', [])
                    commit('setPendingRequests', [])
                })
            }
        },
        logout({commit, dispatch}){
            return userAPI.logout().then(() => {
                return dispatch('empty')
            })
        },
        toggleMenu({commit}) {
            commit("toggleMenu")
        },
        closeMenu({commit}) {
            commit("closeMenu")
        }
    },
    getters: {
        isAuth: state => {
            return state.user != null
        },
        user: state => {
            return state.user
        },
        userEmail: state => {
            // @ts-ignore
            return (state.user && state.user.email) || ''
        },
        userFirstName: state => {
            // @ts-ignore
            return (state.user && state.user.first_name) || ''
        },
        userLastName: state => {
            // @ts-ignore
            return (state.user && state.user.last_name) || ''
        },
        userRole: state => {
            // @ts-ignore
            return (state.user && state.user.role) || ''
        },
        completedRequests: state => {
            return state.completed_requests
        },
        pendingRequests: state => {
            return state.pending_requests
        },
        isAdmin: state => {
            return (state.user && state.user.is_staff)
        },
        menuOpened: state => {
            return state.menuOpened
        }
    },
}
