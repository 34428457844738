























import {Component, Prop, Vue} from 'vue-property-decorator';
import CardInput from "@/components/CardInput.vue";
import BilancioCard from "@/components/FormCard/BilancioCard.vue";
import CheckBoxCard from "@/components/FormCard/CheckBoxCard.vue";
import {requestApi} from "@/api/requests";
import {mapActions, mapGetters} from "vuex";
import {EventBus} from "@/EventBus";

@Component({
  components: {
    BilancioCard,
    CardInput,
    CheckBoxCard
  },
  computed: {
    ...mapGetters('path', [
      'currentRequest',
      'step',
    ])
  }
})


export default class EndRiskAssessment extends Vue {
  private step!: number;
  private currentRequest!: any;
  private disabled: boolean = false;

  save() {
    EventBus.$emit('ForwardIfComplete')
  }
}
