




























































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mapActions, mapGetters} from "vuex";
import {utility} from "@/utility";
import {userAPI} from "@/api/users";
import {envHelper} from "@/EnvHelper";
import store from "@/store";

@Component({
  components: {},
  computed: {
    store() {
      return store
    },
    ...mapGetters('user', [
      'user',
      'userEmail',
      'pendingRequests',
      'completedRequests',
        'isAdmin'
    ])
  },
  methods: {
    ...mapActions('path', [
      'reset',
    ])
  }
})
export default class Dashboard extends Vue {
  private todo = true
  private done = true
  private isAdmin: any;
  private utility = utility
  envHelper = envHelper

  private mobileMenu = false

  created() {
    this.$store.dispatch('user/fetchRequests')
  }

  getActive(id: string) {
    return this.$route.name == 'Request' && this.$route.params.id === id
  }

  logout() {
    this.$store.dispatch('user/logout').then(() => {
      this.$router.push({name: 'Login'})
    })
  }

  newRequest() {
    //@ts-ignore
    this.reset().then(() => {
      this.$router.push({name: 'Dibrief'})
    });

  }

  @Watch('user')
  onUserChange() {
    this.$store.dispatch('user/fetchRequests')
  }

  toggleMobile() {
    this.mobileMenu = !this.mobileMenu
  }

}

