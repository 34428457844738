




import {Component, Prop, Vue} from 'vue-property-decorator';
import RequireAuth from "@/components/RequireAuth.vue";
import moment from "moment";

@Component({
  components: {
  }
})
export default class BDate extends Vue {
    @Prop() private readonly value!:string

    get DateValue(){
        return moment(this.value).format('YYYY-MM-DD')
    }

    set DateValue(val: string){
        this.$emit('input', moment(val).toISOString() )
    }

}
