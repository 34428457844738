
























































import {Component, Prop, Vue} from 'vue-property-decorator';
import CardInput from "@/components/CardInput.vue";
import Card from "@/components/Card.vue";
import InfoAziendaliCard from "@/components/FormCard/InfoAziendaliCard.vue";
import BilancioCard from "@/components/FormCard/BilancioCard.vue";
import OrganicoCard from "@/components/FormCard/OrganicoCard.vue";
import {requestApi} from "@/api/requests";
import {mapGetters} from "vuex";
import {EPercorsi, EPoliciesLoad, EStatus, ETarget, PercorsiSelect} from "@/types";
import {EventBus} from "@/EventBus";
import InfoUserCard from "@/components/FormCard/InfoUserCard.vue";
import CaricamentoBilanciCard from "@/components/FormCard/CaricamentoBilanciCard.vue";
import {utility} from "@/utility";

@Component({
  components: {
    Card,
    BilancioCard,
    CardInput,
    InfoAziendaliCard,
    OrganicoCard,
    InfoUserCard,
    CaricamentoBilanciCard
  },
  computed: {
    ...mapGetters('path', [
      'currentRequest', 'path'
    ])
  },
})


export default class DatiAzienda extends Vue {
  @Prop() private msg!: string;

  ETarget = ETarget

  private user_show_error: boolean = false;
  private percorso_show_error: boolean = false;
  private ia_show_error: boolean = false;
  private bc_show_error: boolean = false;
  private oc_show_error: boolean = false;

  private user_valid: boolean = false;
  private percorso_valid: boolean = false;
  private ia_valid: boolean = false;
  private bc_valid: boolean = false;
  private oc_valid: boolean = false;

  private ia_model: any = {};
  private bc_model: any = {};
  private oc_model: any = {};
  private currentRequest: any;

  private disabled: boolean = false;

  private percorso: EPercorsi | null = null;
  private percorso_options = [{value: null, text: 'Seleziona', disabled: true}, ...PercorsiSelect]

  created() {
    EventBus.$on(`Save${this.$options.name}`, ()=>{
      this.save(false)
    })
    if (this.currentRequest) {
      this.percorso = this.currentRequest.path
    }
    this.changePercorso()

  }

  get percorsoOptions(){
    if (this.isEntePubblico){
      return this.percorso_options.filter((val)=>{
        return val.value == EPercorsi["Ente pubblico"]
      })
    } else {
      return this.percorso_options.filter((val)=>{
        return val.value != EPercorsi["Ente pubblico"]
      })
    }
  }

  beforeDestroy() {
    EventBus.$off(`Save${this.$options.name}`)
  }

  changePercorso() {
    this.percorso_valid = (this.path !== null)
  }

  save(forward: boolean = true) {
    this.disabled = true
    this.user_show_error = !this.user_valid
    this.percorso_show_error = !this.percorso_valid
    this.ia_show_error = !this.ia_valid

    if(this.isEntePubblico){
      this.bc_valid = true;
      this.oc_valid = true;
      this.percorso_valid = true;
    }

    this.bc_show_error = !this.bc_valid
    this.oc_show_error = !this.oc_valid
    if (this.user_valid && this.ia_valid && this.bc_valid && this.oc_valid && this.percorso_valid) {

      //@ts-ignore
      this.$refs['user'].save().finally(() => {
        let req;
        if (this.currentRequest) {
          req = requestApi.requestUpdate(this.currentRequest.id, {
            ...this.getModel(),
            step: this.currentRequest.step
          })
        } else {
          req = requestApi.requestCreate(this.getModel())
        }
        //salvo la richiesta e vado avanti
        req.then((response) => {
          return this.$store.dispatch('path/setRequest', response.data)
        }).then(()=>{
          //@ts-ignore
          return this.$refs['caricamento_bilanci'].send()
        }).finally(() => {
          return this.$store.dispatch('path/setStep', this.path)
        }).then(() => {
          if(forward){
            EventBus.$emit('ForwardIfComplete')
          }
        }).finally(() => {
          this.disabled = false
        })
      })
    } else {
      this.disabled = false
    }

  }

  get isEntePubblico() {
    return this.ia_model.target == ETarget["ente pubblico"]
  }

  get path() {
    if (this.isEntePubblico) {
      return EPercorsi["Ente pubblico"]
    } else {
      return this.percorso
    }
  }

  getModel() {
    return {
      uploadType: EPoliciesLoad.NESSUNO,
      path: this.path || 0,
      company: {
        vatCode: this.ia_model.vatCode,
        businessName: this.ia_model.businessName,
        target: this.ia_model.target,
        legalForm: this.ia_model.legalForm,
        sector: this.ia_model.sector,
        ateco: this.ia_model.ateco,
        market: (this.ia_model.market || []).map((v: any) => v.value),
        employees: parseInt(this.oc_model.employees),
        budgetCurrent: this.bc_model.budgetCurrent,
        budgetEstimated: this.bc_model.budgetEstimated
      }
    }
  }
}
