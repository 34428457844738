import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import StepDispathcer from "@/views/StepDispathcer.vue";
import NavBar from "@/components/NavBar.vue";
import Dashboard from "@/views/Dashboard.vue";
import Account from "@/components/Account.vue";
import RequestDetail from "@/components/RequestDetail.vue";
import AdminDashboard from "@/views/AdminDashboard.vue";
import Requests from "@/components/Requests.vue";
import Users from "@/components/Users.vue";
import Page404 from "@/views/Page404.vue";
import VueGtm from '@gtm-support/vue2-gtm';
import {envHelper} from "@/EnvHelper";
import store from "@/store";


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Start',
        redirect: to => {
            return 'SignUp'
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        props: {
            nav: {dibrief: false, light: false},
        },
        components: {
            default: Dashboard,
            // nav: NavBar
        },
        children: [
            {
                name: 'Account',
                path: 'account',
                component: Account
            },
            {
                name: 'Request',
                path: 'request/:id/',
                component: RequestDetail,
                props: true
            },
        ]
    },
    {
        path: '/admin',
        name: 'Admin',
        props: {
            nav: {dibrief: false, light: false},
        },
        components: {
            default: AdminDashboard,
            // nav: NavBar
        },
        children: [
            {
                name: 'AdminAccount',
                path: 'account',
                component: Account
            },
            {
                name: 'Users',
                path: 'users/',
                component: Users,
                props: true
            },
            {
                name: 'Requests',
                path: 'requests/',
                component: Requests,
                props: true
            },
            {
                name: 'AdminRequestDetail',
                path: 'requests/:id/',
                component: RequestDetail,
                props: true
            },
        ]
    },
    {
        path: '/dibrief',
        name: 'Dibrief',
        props: {
            nav: {dibrief: true, light: false},
        },
        components: {
            default: StepDispathcer,
            nav: NavBar
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/signin',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        components: {
            default: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
            nav: () => import(/* webpackChunkName: "about" */ '../components/NavBar.vue'),
        },
        meta: {
            skipIfAuth: 'Start'
        }
    },
    {
        path: '/signup',
        name: 'SignUp',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        components: {
            default: () => import(/* webpackChunkName: "about" */ '../views/SignUp.vue'),
            nav: () => import(/* webpackChunkName: "about" */ '../components/NavBar.vue'),
        },
        meta: {
            skipIfAuth: 'Login'
        }
    },
    {
        path: '/reset',
        name: 'ResetPwd',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        components: {
            default: () => import(/* webpackChunkName: "about" */ '../views/ResetPwd.vue'),
            nav: () => import(/* webpackChunkName: "about" */ '../components/NavBar.vue'),
        },
        meta: {
            skipIfAuth: 'Login'
        }
    },
    {
        path: '/dibrief/:path(\\d_.*)/:step(\\d_.*)/',
        name: 'Dibrief2',
        props: {
            nav: {dibrief: true, light: false},
        },
        components: {
            default: StepDispathcer,
            nav: NavBar
        },
        meta: {
            requiresAuth: true
        }
    },
    {path: "*", component: Page404}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (!store.getters["user/isAuth"] && to.name !== 'SignUp' && to.name !== 'Login') {
        next({'name': 'SignUp'})
    } else {
        if (to.name?.length) {
            document.title = to.name
        } else {
            document.title = "DIBRIEF"
        }
        next()
        return store.dispatch('user/closeMenu')
    }
})

if (envHelper.GTMId()) {
    Vue.use(VueGtm, {
        id: envHelper.GTMId(),
        vueRouter: router
    })
}

export default router
