






































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mapActions, mapGetters} from "vuex";
import Card from "@/components/Card.vue";
import CheckBoxQuestion from "@/components/FormCard/CheckBoxQuestion.vue";
import VeicoliCard from "@/components/FormCard/VeicoliCard.vue";
import RealEstateTableCard from "@/components/FormCard/RealEstateTableCard.vue";
import InsuraceContractsTableCard from "@/components/FormCard/InsuraceContractsTableCard.vue";
import ClaimsCoveredTableCard from "@/components/FormCard/ClaimsCoveredTableCard.vue";
import ClaimsNotCoveredTableCard from "@/components/FormCard/ClaimsNotCoveredTableCard.vue";
import StaffTableCard from "@/components/FormCard/StaffTableCard.vue";
import WagesTableCard from "@/components/FormCard/WagesTableCard.vue";
import {
  PublicAuthorityInterface, PublicAuthorityRealEstateFornitureInterface,
  PublicAuthorityRealEstateItemInterface,
  PublicAuthorityStaffInterface,
  PublicAuthorityWagesInterface
} from "@/api/RequestsInterface";
import {requestApi} from "@/api/requests";
import {EventBus} from "@/EventBus";

@Component({
  computed: {
    ...mapGetters({
      'currentRequest': 'path/currentRequest',
      'publicAuthority': 'path/publicAuthority',
    }),
  },
  methods: {
    ...mapActions('path', [
      'refreshPublicAuthority',
      'refreshRequest'
    ])
  },
  components: {
    Card,
    CheckBoxQuestion,
    VeicoliCard,
    RealEstateTableCard,
    InsuraceContractsTableCard,
    ClaimsCoveredTableCard,
    ClaimsNotCoveredTableCard,
    StaffTableCard,
    WagesTableCard,
  }
})
export default class EntePubblico extends Vue {
  private disabled: boolean = false;
  private currentRequest: any;
  private publicAuthority?: PublicAuthorityInterface;
  private address: string = '';
  private structural_changes: boolean = false
  private structural_changes_note: string = ''
  private structural_investments: boolean = false
  private structural_investments_note: string = ''
  private risk_area_infrastructure_use_value: boolean = false
  private risk_area_infrastructure_use_km: number = 0
  private risk_area_photovoltaic: boolean = false
  private risk_area_insurance_value: boolean = false
  private risk_area_insurance_description: string = ''

  // private real_estate_description: string = ''
  // private real_estate_forniture_total_value: number = 0
  // private real_estate_mobilio_value: number = 0
  // private real_estate_mobilio_description: string = ''
  // private real_estate_elaboratori_value: number = 0
  // private real_estate_elaboratori_description: string = ''


  created() {
    EventBus.$on(`Save${this.$options.name}`, () => {
      this.save(false);
    });
    if (this.publicAuthority) {
      this.address = this.publicAuthority.address || '';
      this.structural_changes = this.publicAuthority.structural_changes || false;
      this.structural_changes_note = this.publicAuthority.structural_changes_note || '';
      this.structural_investments = this.publicAuthority.structural_investments || false;
      this.structural_investments_note = this.publicAuthority.structural_investments_note || '';

      this.risk_area_infrastructure_use_value = this.publicAuthority.risk_area_infrastructure_use_value || false;
      this.risk_area_infrastructure_use_km = this.publicAuthority.risk_area_infrastructure_use_km || 0;
      this.risk_area_photovoltaic = this.publicAuthority.risk_area_photovoltaic || false;
      this.risk_area_insurance_value = this.publicAuthority.risk_area_insurance_value || false;
      this.risk_area_insurance_description = this.publicAuthority.risk_area_insurance_description || '';

      //this.real_estate_description = this.publicAuthority.real_estate_description || ''
      //this.real_estate_forniture_total_value = this.publicAuthority.real_estate_forniture_total_value || 0
      //this.real_estate_mobilio_value = this.publicAuthority.real_estate_mobilio_value || 0
      //this.real_estate_mobilio_description = this.publicAuthority.real_estate_mobilio_description || ''
      //this.real_estate_elaboratori_value = this.publicAuthority.real_estate_elaboratori_value || 0
      //this.real_estate_elaboratori_description = this.publicAuthority.real_estate_elaboratori_description || ''


    }
  }

  beforeDestroy() {
    EventBus.$off(`Save${this.$options.name}`)
  }

  save(forward: boolean = true) {
    this.disabled = true
    // @ts-ignore
    this.$refs['veicoli'].saveCars().then(() => {

      let real_estate_data = (this.$refs['real_estate'] as any).getData()

      let data = {
        address: this.address,
        structural_changes: this.structural_changes,
        structural_changes_note: this.structural_changes_note,
        structural_investments: this.structural_investments,
        structural_investments_note: this.structural_investments_note,

        staff: (this.$refs['staff'] as any).getData() as PublicAuthorityStaffInterface[],
        wages: (this.$refs['wages'] as any).getData() as PublicAuthorityWagesInterface[],

        real_estate_description: real_estate_data.real_estate_description,
        real_estate_forniture_total_value: real_estate_data.real_estate_forniture_total_value,
        real_estate_list: real_estate_data.real_estate_list,
        real_estate_furniture: real_estate_data.real_estate_furniture,

        risk_area_infrastructure_use_value: this.risk_area_infrastructure_use_value,
        risk_area_infrastructure_use_km: this.risk_area_infrastructure_use_km,
        risk_area_photovoltaic: this.risk_area_photovoltaic,
        risk_area_insurance_value: this.risk_area_insurance_value,
        risk_area_insurance_description: this.risk_area_insurance_description,

        insurance_contracts: (this.$refs['insurance_contracts'] as any).getData(),
        claims_covered: (this.$refs['claims_covered'] as any).getData(),
        claims_not_covered: (this.$refs['claims_not_covered'] as any).getData().claims_not_covered,
        claims_not_covered_description: (this.$refs['claims_not_covered'] as any).getData().claims_not_covered_description
      }


      requestApi.requestPublicAuthorityCreate(this.currentRequest.id, data).then(() => {
        // @ts-ignore
        return this.refreshRequest().then(()=>{
          if (forward) {
            console.log('forward')
            EventBus.$emit('ForwardIfComplete')
          }
        })
      }).finally(() => {
        this.disabled = false
      })

    })


  }

}
