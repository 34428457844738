import Vue from 'vue'
import Vuex from 'vuex'
import {pathModule} from "./pathModule";
import {userModule} from "./userModule";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters:{
  },
  modules: {
    path: pathModule,
    user: userModule,
  }
})
