

















import {Component, Prop, Vue} from 'vue-property-decorator';
import Step from "@/components/Step.vue";
import {mapActions, mapGetters} from "vuex";
import {utility} from "@/utility";

@Component({
  components: {
    Step
  },
  computed: {
    ...mapGetters('path', [
      'currentRequest', 'path'
    ])
  },
})
export default class Stepper extends Vue {

  @Prop({default: true}) private readonly loadFromStore!: boolean

  private currentRequest: any;

  handleNavigation(step: number) {
    if (this.currentRequest) {
      if (step <= this.currentRequest.step) {
        let url = utility.getUrlFromState(this.currentRequest.path, step)
        this.$router.push(url)
      }
    }
  }

  get Step() {
    if (this.loadFromStore) {
      return this.$store.getters['path/step']
    }
    return -1
  }

  get stepsList() {
    if (this.loadFromStore) {
      return this.$store.getters['path/stepperRoute']
    }
    return [1, 2, 3, 4]
  }
}
