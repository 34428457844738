


















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import InfoPoint from "@/components/InfoPoint.vue";
import Dnd from "@/components/Dnd.vue";
import {requestApi} from "@/api/requests";
import {mapActions, mapGetters} from "vuex";
import {CarUseSelect, CompanySelect, CVTSelect, EAssets, ECvt} from "@/types";
import {EventBus} from "@/EventBus";
import BDate from "@/views/b-date.vue";

@Component({
  components: {
    BDate,
    Card,
    CardInput,
    InfoPoint,
    Dnd
  },
  computed: {
    ...mapGetters('path', [
      'currentRequest',
      'getVehicleCSV',
      'getVehicles'
    ])
  },
  methods: {
    ...mapActions('path', [
      'refreshRequest',
    ])
  }
})
export default class VeicoliCard extends Vue {

  @Prop({default: false}) private readonly readonly!: boolean

  private currentRequest: any
  private ECvt = ECvt

  private CarUseSelect = CarUseSelect
  private CVTSelect = CVTSelect
  private CompanySelect = CompanySelect
  private triggerComplete: boolean = false
  private isLoading: boolean = false
  private modified: boolean = false

  private rows: any[] = []

  FormatDate(date: string) {
    return new Date(date).toISOString()
  }

  send(files: File[]) {
    if (this.readonly) return;
    this.isLoading = true

    let uploads = files.map((file) => {
      return requestApi.requestAssetsCreate(this.currentRequest.id, file, EAssets.VEICOLO).then(() => {
      })
    })

    Promise.all(uploads).then(() => {
      this.triggerComplete = !this.triggerComplete
      // @ts-ignore
      this.refreshRequest()
    }).finally(() => {
      this.isLoading = false
    })

  }

  handleFileRemove(id: string) {
    if (this.readonly) return;
    this.isLoading = true
    requestApi.requestAssetsDestroy(this.currentRequest.id, id).then(() => {
      // @ts-ignore
      this.refreshRequest()
    }).finally(() => {
      this.isLoading = false
    })
  }

  get Items() {
    // @ts-ignore
    return [...this.getVehicles, ...this.rows]
  }

  private fields = [
    {key: 'n', label: '', tdClass:'first-row number-sizing'},
    {key: 'licensePlate', label: 'targa'},
    {key: 'carModel', label: 'marca/modello'},
    {key: 'carUse', label: 'uso'},
    {
      key: 'garanzia',
      label: 'garanzia CVT'
    },
    {key: 'insYearly', label: 'premio lordo annuo'},
    {
      key: 'insCompany',
      label: 'compagnia associativa'
    },
    {key: 'insExpiration', label: 'scadenza polizza'},
    {key: 'delete', label: '', tdClass:'last-row'},
  ]

  addRow() {
    if (this.readonly) return;
    this.rows.push({
      licensePlate: '',
      carModel: '',
      carUse: null,
      garanzia: [],
      insYearly: 0,
      insCompany: '',
      insExpiration: ''
    },)
    this.modified = false
  }

  async removeRow(row: any) {
    this.isLoading = true
    if (this.readonly) return;
    this.rows.splice(row.index - this.Items.length, 1)
    if (row.item.id) {
      await requestApi.requestVehiclesDestroy(this.currentRequest.id, row.item.id)
      // @ts-ignore
      this.refreshRequest()
    }
    this.isLoading = false
  }

  saveCars(refreshRequest: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this.readonly) return;
      const done: any[] = []
      if (!this.isCarValid()) {
        reject()
        return;
      }
      this.Items.forEach((row: any) => {
        let ret;
        try {
          row.insExpiration = new Date(row.insExpiration).toISOString().substr(0, 10)
        } catch (e) {
          row.insExpiration = new Date('1970-01-01').toISOString().substr(0, 10)
        }
        if (row.id) {
          ret = requestApi.requestVehiclesUpdate(this.currentRequest.id, row.id, row)
        } else {
          ret = requestApi.requestVehiclesCreate(this.currentRequest.id, row)
        }
        done.push(ret)
      })

      Promise.all(done).then((values) => {
        this.rows.splice(0)
        if (refreshRequest) {
          // @ts-ignore
          this.refreshRequest()
        }
        this.modified = false
        resolve();
      }).catch(reject)
    })
  }

  isCarValid() {
    return !this.Items.some((r) => {
      return !r.licensePlate || !r.carModel || r.carUse == null || r.insCompany === '' || !r.insExpiration
    })
  }

  setChange(row: any) {
    Vue.set(row.item, 'changed', true)
    this.modified = this.isCarValid()
  }

}
