



































import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from "@/components/Card.vue";
import {DetailResponseInterface} from "@/types";

@Component({
    components: {
        Card
    }
})
export default class RecapCard extends Vue {
    @Prop({default: 'Domanda'}) private readonly firstColLabel!: string
    @Prop({default: 'Risposta'}) private readonly secondColLabel!: string
    @Prop({default: false}) private readonly hasAssets!: boolean
    @Prop({default: true}) private readonly hasResponse!: boolean

    @Prop({
        default: () => {
            return []
        }
    }) private readonly rows!: DetailResponseInterface[]

}
