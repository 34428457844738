








import {Component, Prop, Vue} from 'vue-property-decorator';
import Step from "@/components/Step.vue";
import {EventBus} from "@/EventBus";

@Component({
  components: {}
})
export default class HelpModal extends Vue {

  private visible: boolean = false;
  private title: string = 'titolo';
  private text: string = 'testo';

  created() {
    EventBus.$on('showHelp', (evt: any)=> {
      this.visible = true
      this.title = evt.title
      this.text = evt.text
    })

    EventBus.$on('hideHelp', ()=> {
      this.visible = true
    })

    EventBus.$on('toggleHelp', ()=> {
      this.visible = !this.visible
    })
  }
}
