import {Module} from "vuex";
import {dibLs} from "./storage";
import {requestApi} from "@/api/requests";
import {EAssets, EPercorsi} from "@/types";
import {PoliciesBlockInterface, PoliciesResponse} from "@/api/PoliciesInterface";
import {QuestionBlockInterface, QuestionResponse} from "@/api/QuestionInterface";

export const pathModule: Module<any, any> = {
    namespaced: true,
    state: {
        stepperRoute: dibLs.loadStepper(),
        step: dibLs.loadStep(),
        path: dibLs.loadPath(),
        currentRequest: null,
        publicAuthority: null,
        questions: [],
        answers: [],
        vehicles: [],
        assets: [],
        policies: [],
        policy_answers: [],
        requests: [],
        users: [],
    },
    mutations: {
        reset(state) {
            state.stepperRoute = dibLs.loadStepper();
            state.publicAuthority = null;
            state.questions = [];
            state.answers = [];
            state.vehicles = [];
            state.assets = []
            state.policies = []
            state.policy_answers = []
            state.currentRequest = null;
            state.path = 0;
            state.step = 0;
            dibLs.setStepper(0, 0).save()
        },
        continueRequest(state, {request, publicAuthority, questions, answers, vehicles, assets, policies, policy_answers, path, step}) {
            dibLs.setStepper(path, step).save()
            state.currentRequest = request
            state.publicAuthority = publicAuthority
            state.questions = questions
            state.answers = answers
            state.vehicles = vehicles
            state.assets = assets
            state.policy_answers = policy_answers
            state.policies = policies
            state.stepperRoute = dibLs.loadStepper()
            state.step = dibLs.loadStep()
        },
        setRequest(state, {request, publicAuthority, questions, answers, vehicles, assets, policies, policy_answers}) {
            state.currentRequest = request
            state.publicAuthority = publicAuthority
            state.questions = questions
            state.answers = answers
            state.vehicles = vehicles
            state.assets = assets
            state.policies = policies
            state.policy_answers = policy_answers
        },
        setPublicAuthority(state, publicAuthority) {
            state.publicAuthority = publicAuthority
        },
        setAssets(state, assets) {
            state.assets.splice(0, state.assets.length, ...assets)
        },
        setVehicles(state, vehicles) {
            state.vehicles.splice(0, state.vehicles.length, ...vehicles)
        },
        setPolicies(state, policies) {
            state.policies.splice(0, state.policies.length, ...policies)
        },
        setPolicyAnswers(state, policies) {
            state.policy_answers.splice(0, state.policy_answers.length, ...policies)
        },
        setQuestions(state, questions) {
            state.questions.splice(0, state.questions.length, ...questions)
        },
        setAnswers(state, answers) {
            state.answers.splice(0, state.answers.length, ...answers)
        },
        setStepper(state, {path, step}) {
            state.stepperRoute = dibLs.getStepper(path);
            state.path = path;
            state.step = step;
            dibLs.setStepper(path, step).save()
        },
        setStep(state, path) {
            state.stepperRoute = dibLs.getStepper(path);
            state.step = 0;
            state.path = path;
            dibLs.setStepper(path, 0).save()
        },
        setOrUpdateSinglePolicy(state, p) {
            const idx = state.policies.findIndex((el: PoliciesResponse) => {
                return el.id === p.id
            })
            if (idx === -1) {
                state.push(p)
            } else {
                state[idx] = p
            }
        },
        inc(state) {
            if (state.step < state.stepperRoute.length) {
                state.step++;
            }
        },
        last(state) {
            state.step = state.stepperRoute.length - 1
        },
        dec(state) {
            if (state.step > 0) {
                state.step--;
            }
        },
        goto(state, id) {
            if (id >= 0 && id < state.stepperRoute.length) {
                state.step = id
            }
        },
        setRequests(state, rqs) {
            state.requests = rqs
        },
        setUsers(state, rqs) {
            state.users = rqs
        }
    },
    actions: {
        reset({commit}) {
            commit('reset')
        },
        continueRequest({commit}, {request, path, step}) {
            const avpq = [
                requestApi.requestAssetsRetrieve(request.id),
                requestApi.requestVehiclesRetrieve(request.id),
                requestApi.requestPoliciesRetrieve(request.id),
                requestApi.requestPolicyAnswersRetrive(request.id),
                requestApi.requestQuestionBlocksRetrieve(request.id),
                requestApi.requestAnswersRetrive(request.id),
                requestApi.requestPublicAuthorityRetrieve(request.id)
            ]
            return Promise.all(avpq).then((res) => {
                commit('continueRequest', {
                    request,
                    assets: res[0].data,
                    vehicles: res[1].data,
                    policies: res[2].data,
                    policy_answers: res[3].data,
                    questions: res[4].data,
                    answers: res[5].data,
                    publicAuthority: res[6].data,
                    path,
                    step
                })
            })
        },
        setRequest({commit}, request) {
            const avpq = [
                requestApi.requestAssetsRetrieve(request.id),
                requestApi.requestVehiclesRetrieve(request.id),
                requestApi.requestPoliciesRetrieve(request.id),
                requestApi.requestPolicyAnswersRetrive(request.id),
                requestApi.requestQuestionBlocksRetrieve(request.id),
                requestApi.requestAnswersRetrive(request.id),
                requestApi.requestPublicAuthorityRetrieve(request.id)
            ]

            return Promise.all(avpq).then((res) => {
                commit('setRequest', {
                    request,
                    assets: res[0].data,
                    vehicles: res[1].data,
                    policies: res[2].data,
                    policy_answers: res[3].data,
                    questions: res[4].data,
                    answers: res[5].data,
                    publicAuthority: res[6].data,
                })
            })
        },
        //fixme: cosa brutale ma è la più sicura, volendo andare a vedere dove è usata e raffinarla
        refreshRequest({state, commit, dispatch}) {
            if (state.currentRequest) {
                return requestApi.requestRetrieve(state.currentRequest.id).then((res) => {
                    console.log('set_request')
                    return dispatch('setRequest', res.data)
                })
            }
        },
        refreshPublicAuthority({state, commit}){
            if (state.currentRequest) {
                requestApi.requestPublicAuthorityRetrieve(state.currentRequest.id).then((res) => {
                    commit('setPublicAuthority', res.data)
                })
            }
        },
        refreshQuestions({state, commit}) {
            if (state.currentRequest) {
                requestApi.requestQuestionBlocksRetrieve(state.currentRequest.id).then((res) => {
                    commit('setQuestions', res.data)
                })
            }
        },
        refreshAnswers({state, commit}) {
            if (state.currentRequest) {
                requestApi.requestAnswersRetrive(state.currentRequest.id).then((res) => {
                    commit('setAnswers', res.data)
                })
            }
        },
        refreshAssets({state, commit}) {
            if (state.currentRequest) {
                return requestApi.requestAssetsRetrieve(state.currentRequest.id).then((as) => {
                    commit('setAssets', as.data)
                })
            }
        },
        refreshPolicies({state, commit}) {
            if (state.currentRequest) {
                requestApi.requestPoliciesRetrieve(state.currentRequest.id).then((res) => {
                    commit('setPolicies', res.data)
                })
            }
        },
        refreshPolicyAnswers({state, commit}) {
            if (state.currentRequest) {
                return requestApi.requestPolicyAnswersRetrive(state.currentRequest.id).then((res) => {
                    commit('setPolicyAnswers', res.data)
                })
            }
        },
        setSinglePolicy({state, commit}, policy) {
            commit('setOrUpdateSinglePolicy', policy)
        },
        setStepper({commit}, {path, step}) {
            commit('setStepper', {path, step})
        },
        setStep({commit}, path) {
            commit('setStep', path)
        },
        goto({commit}, id) {
            commit('goto', id)
        },
        next({commit}) {
            commit('inc')
        },
        last({commit}) {
            commit('last')
        },
        prev({commit}) {
            commit('dec')
        },
        fetchRequests({state, commit}){
            return requestApi.requestsRetrieve().then((res)=>{
                commit('setRequests', res.data)
            })
        },
        fetchUsers({state, commit}){
            return requestApi.usersRetrieve().then((res)=>{
                commit('setUsers', res.data)
            })
        },
        updateRequest({state, commit, dispatch}, data){
            return requestApi.requestUpdate(state.currentRequest.id, data).then((res) => {
                dispatch('setRequest', res.data)
            })
        },
        loadRequest({state, commit, dispatch}, requestId){
            commit('setRequest', {request: {id: requestId}})
            return dispatch('refreshRequest')
        }
    },
    getters: {
        stepperRoute: state => {
            return state.stepperRoute
        },
        path: state => {
            return state.path
        },
        step: state => {
            return state.step
        },
        isLast: state => {
            return state.step === state.stepperRoute.length - 1
        },
        isFirst: state => {
            return state.step === 0
        },
        getState: state => {
            return state.stepperRoute[state.step]
        },
        currentRequest: state => {
            return state.currentRequest
        },
        publicAuthority: state => {
            return state.publicAuthority
        },
        getQuestions: state => {
            if(!state.questions){
                return []
            }
            return state.questions.map((qs: QuestionBlockInterface)=>{
                return {
                    title: qs.title,
                    questions: qs.questions.map((el) => {

                        const find = state.answers.find((res: QuestionResponse) => {
                            return res.question === el.id
                        })
                        return {
                            value: find ? find.value : null,
                            description: el.description,
                            question: el.id
                        }
                    })
                }
            })
        },
        getAnswers: state => {
            return state.answers
        },
        getPolicies: state => {
            if(!state.policies){
                return []
            }

            return state.policies.map((ps: PoliciesBlockInterface)=>{

                const find = state.policy_answers.find((res: PoliciesResponse) => {
                    return res.policy === ps.id
                })

                return {
                    valid: false,
                    title: ps.title,
                    policy: ps.id,
                    description: ps.description,
                    value: find
                }
            })

        },
        getPolicyAnswers: state => {
            return state.policy_answers
        },
        getVehicleCSV: state => {
            if(!state.assets){
                return []
            }
            return state.assets.filter((res: any) => {
                return res.type === EAssets.VEICOLO
            })
        },
        getMassiveAssets: state => {
            if(!state.assets){
                return []
            }
            return state.assets.filter((res: any) => {
                return res.type === EAssets.POLIZZA
            })
        },
        getMandatiAssets: state => {
            if(!state.assets){
                return []
            }
            return state.assets.filter((res: any) => {
                return res.type === EAssets.PROPOSTA
            })
        },
        getBilancioConsuntivoEntePubblicoAssets: state => {
            if(!state.assets){
                return []
            }
            return state.assets.filter((res: any) => {
                return res.type === EAssets.BILANCIO_CONSUNTIVO_ENTE_PUBBLICO
            })
        },
        getBilancioPrevisionaleEntePubblicoAssets: state => {
            if(!state.assets){
                return []
            }
            return state.assets.filter((res: any) => {
                return res.type === EAssets.BILANCIO_PREVISIONALE_ENTE_PUBBLICO
            })
        },
        getVehicles: state => {
            return state.vehicles
        },
        getRealEstateList: state => {
            return state.real_estate?.list
        },
        canGoForward: state => {
            if(state.currentRequest){
                return state.step < state.currentRequest.step
            }
            return false
        },
        getRequests: state => {
            return state.requests
        },
        getUsers: state => {
            return state.users
        }
    },
}
