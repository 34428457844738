import { render, staticRenderFns } from "./Radio.vue?vue&type=template&id=2f94b099&scoped=true&"
import script from "./Radio.vue?vue&type=script&lang=ts&"
export * from "./Radio.vue?vue&type=script&lang=ts&"
import style0 from "./Radio.vue?vue&type=style&index=0&id=2f94b099&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f94b099",
  null
  
)

export default component.exports